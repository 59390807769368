import { HardwareCommonDataType, sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { utils_instance } from '../utils/Utils';

enum downstreamReducerTypes {
  setHardwareDownstreamDataList = 'downstream/setHardwareDownstreamDataList',
  setSortDownstreamData = 'downstream/setSortDownstreamData',
}

export const setHardwareDownstreamDataListAC = (
  hardwareDownstreamDataList: HardwareCommonDataType[],
) => {
  //dispatch main hardware downstream data list
  return {
    type: downstreamReducerTypes.setHardwareDownstreamDataList,
    hardwareDownstreamDataList,
  } as const;
};

export const setSortDownstreamDataAC = (keySort: sortKeyEnum) => {
  //dispatch sort downstream data
  return {
    type: downstreamReducerTypes.setSortDownstreamData,
    keySort,
  } as const;
};

export type setHardwareDownstreamDataListAT = ReturnType<typeof setHardwareDownstreamDataListAC>;
export type setSortDownstreamDataAT = ReturnType<typeof setSortDownstreamDataAC>;

export type actionType = setHardwareDownstreamDataListAT | setSortDownstreamDataAT;

type InitStateType = {
  hardwareDownstreamDataList: HardwareCommonDataType[];
  sortInfo: sortInfoType;
};

export const initState: InitStateType = {
  hardwareDownstreamDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.CommonName,
    directionSort: 1,
  },
};

export const downstreamReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case downstreamReducerTypes.setHardwareDownstreamDataList: {
    const copyState = { ...state };

    const listFavorites: HardwareCommonDataType[] = [];
    const listNotFavorites: HardwareCommonDataType[] = [];

    action.hardwareDownstreamDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareDownstreamDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareDownstreamDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.hardwareDownstreamDataList = [...sortFavorites, ...sortNotFavorites];

    return copyState;
  }

  case downstreamReducerTypes.setSortDownstreamData: {
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
        state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    const listFavorites: HardwareCommonDataType[] = [];
    const listNotFavorites: HardwareCommonDataType[] = [];

    state.hardwareDownstreamDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareDownstreamDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareDownstreamDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.hardwareDownstreamDataList = [...sortFavorites, ...sortNotFavorites];
    copyState.sortInfo.keySort = action.keySort;

    return copyState;
  }

  default: {
    return state;
  }
  }
};
