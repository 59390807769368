import { navigationGroupType, navigationItem, paginationType, PathNames } from '../types/types';
import {
  DeviceDataType,
  deviceStatusDescriptionsType,
  deviceStatusNamesEnum,
} from '../types/deviceInfoTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import {
  HardwareCommonDataType,
  HardwareControllerData,
  HardwareReaderDataType,
  readersActionDescriptionsType,
  readersActionNamesEnum,
  readersStatusDescriptionsType,
  readerStatusNamesEnum,
} from '../types/readersTypes';
import {
  controllersStatusDescriptionsType,
  controllerStatusNamesEnum,
} from '../types/controllersTypes';
import {
  downstreamStatusDescriptionsType,
  downstreamStatusNamesEnum,
} from '../types/downstreamTypes';
import { InstanceExtendedPublicDataType, InstancePublicDataType } from '../types/appTypes';
import icon_observer from '../assets/icons/IconObserverPanel.svg';
import icon_admin_panel from '../assets/icons/IconAdminPanel.svg';
import icon_obliterator from '../assets/icons/IconObliterator.svg';
import icon_building from '../assets/icons/IconBuilding.svg';
import { UserPermissionsType, UserPublicDataType } from '../types/authTypes';
import { scheduleModeType } from '../components/modules/ScheduleModal/ScheduleChildModal';
import jwt_decode from 'jwt-decode';
import { FloorGroupMainDataType } from '../types/elevatorsTypes';
import {
  DaysOfWeek,
  HolidayTriggerDayModeEnum,
  HolidayTriggerTimeModeEnum,
  ScheduleMainDataType
} from '../types/schedulesTypes';
import { ChartOptions } from 'chart.js';
import { intercomStatusNamesEnum } from '../types/IntercomTypes';
import { InstanceGroup, InstanceGroupListType } from '../types/instancesGroupsTypes';
import {
  CountingAreaItem,
  countingAreasActionNamesEnum,
  countingAreasStatusDescriptionsType, countingAreasStatusNamesEnum
} from '../types/countingAreasTypes';

class Utils {
  getFileName = (prefix: string, ext: string): string => {
    const date = new Date();
    const name = date.toISOString().replace('Z', '').split('T').join('_');
    return `${prefix}_${name}.${ext}`;
  };

  getPaginationList = (pagination: paginationType, totalCount: number) => {
    const pagesCount = Math.ceil(totalCount / pagination.limit);

    const tempArr: number[] = [];

    tempArr.push(1);

    if (pagination.page > 5) {
      tempArr.push(-1);
    }

    for (let i = 2; i < pagesCount; i++) {
      if (pagination.page - 4 < i && pagination.page + 4 > i) {
        tempArr.push(i);
      }
    }

    if (pagination.page < pagesCount - 4) {
      tempArr.push(-1);
    }

    if (pagesCount > 1) {
      tempArr.push(pagesCount);
    }

    return tempArr;
  };

  getRegFilter = (selectFloor: number) => {
    return RegExp(`${'-0' + selectFloor + '-'}|([0-9]+)-${selectFloor}\\.([0-9]+)|-${selectFloor}f|${selectFloor}th fl|-${selectFloor}th fl|${selectFloor}fl|${selectFloor}th|${selectFloor}st|${selectFloor}nd|${selectFloor}nd fl|${selectFloor}rd|${selectFloor}rd fl|${selectFloor}st fl|${selectFloor}st|${selectFloor}nd|${selectFloor} rd|${selectFloor}fl|${selectFloor} fl|${selectFloor}th`,); // eslint-disable-line
  };

  parseFullDate = (draftDate?: string): string => {
    // TODO  refactor
    if (!draftDate) {
      return 'No date';
    }
    const date = new Date(draftDate);
    const mediumTime = new Intl.DateTimeFormat('en', {
      timeStyle: 'medium',
      dateStyle: 'short',
    });
    return mediumTime.format(date);
  };

  parseFullDateTimezone = (draftDate: Date) => {
    const mediumTime = new Intl.DateTimeFormat('en', {
      timeStyle: 'medium',
      dateStyle: 'short',
      timeZone: 'UTC',
    });
    return mediumTime.format(draftDate);
  };

  parseFullDateForSchedule = (draftDate: Date) => {
    const mediumTime = new Intl.DateTimeFormat('en', {
      timeStyle: 'medium',
      dateStyle: 'short',
    });
    return mediumTime.format(draftDate);
  };

  //TODO fix
  alertReaderStatus = (platform: string | undefined, status: any) => {
    switch (platform) {
    case '1':
      return status?.DoorHeldOpen;
    case '2':
      return false;
    default:
      return false;
    }
  };

  //TODO refactor
  colorReaderStatus = (platform: string, status: any): string => {
    if (!status) {
      return 'circle__gray';
    }
    switch (platform) {
    case '1':
      return status
        ? status.Mode === 'Card Only'
          ? status.DoorHeldOpen
            ? 'circle__red'
            : 'circle__green'
          : status.Mode === 'Unlocked'
            ? 'circle__yellow'
            : status.Mode === 'Locked Down'
              ? 'circle__red'
              : 'circle__gray'
        : 'circle__gray';
    case '2':
      switch (status.StatusName) {
      case 'Ready':
        return 'circle__green';
      case 'Extended Unlock':
      case 'Shunted Open':
      case 'Shunted Closed':
        return 'circle__yellow';
      case 'Forced':
      case 'Held':
        return 'circle__red';
      default:
        return 'circle__gray';
      }
    default:
      return 'circle__gray';
    }
  };

  colorControllerStatus = (status: controllerStatusNamesEnum): string => {
    switch (status) {
    case controllerStatusNamesEnum.Online:
    case controllerStatusNamesEnum.Connected:
      return 'circle__green';
    case controllerStatusNamesEnum.Tampered:
      return 'circle__yellow';
    case controllerStatusNamesEnum.Offline:
    case controllerStatusNamesEnum.NotEnabled:
      return 'circle__red';
    default:
      return 'circle__gray';
    }
  };

  colorDownstreamStatus = (status: downstreamStatusNamesEnum): string => {
    switch (status) {
    case downstreamStatusNamesEnum.Online:
      return 'circle__green';
    case downstreamStatusNamesEnum.Offline:
      return 'circle__red';
    default:
      return 'circle__gray';
    }
  };

  parseDeviceData = (item: DeviceDataType, keySort: sortKeyEnum | null): string => {
    switch (keySort) {
    case sortKeyEnum.FullName:
      return item.attributes.full_name.split('(')[0];
    case sortKeyEnum.IpAddress:
      return item.attributes.ip_address;
    case sortKeyEnum.MacAddress:
      return item.attributes.mac_address;
    case sortKeyEnum.Location:
      return item.attributes.location ? item.attributes.location : '';
    default:
      return '';
    }
  };

  parseCameraStatus = (status?: number): deviceStatusNamesEnum => {
    if (status !== undefined) {
      return status === 1 ? deviceStatusNamesEnum.Online : deviceStatusNamesEnum.Offline;
    }
    return deviceStatusNamesEnum.Unknown;
  };

  parseIntercomStatus = (status?: string): intercomStatusNamesEnum => {
    if (status !== undefined) {
      return status ? intercomStatusNamesEnum.Online : intercomStatusNamesEnum.Offline;
    }
    return intercomStatusNamesEnum.Unknown;
  };

  colorCameraStatus = (status: deviceStatusNamesEnum): string => {
    switch (status) {
    case deviceStatusNamesEnum.Online:
      return 'circle__green';
    case deviceStatusNamesEnum.Offline:
      return 'circle__red';
    default:
      return 'circle__gray';
    }
  };

  colorIntercomStatus = (status: intercomStatusNamesEnum): string => {
    switch (status) {
    case intercomStatusNamesEnum.Online:
      return 'circle__green';
    case intercomStatusNamesEnum.Offline:
      return 'circle__red';
    default:
      return 'circle__gray';
    }
  };

  validateScheduleTime = (
    selectedInitTimeDate: number,
    minInitTimeDate: number,
    selectedTimeDuration: number,
    isRegularMode: boolean,
    selectedDays: string[],
    setHelperText: (val: string | null) => void,
  ) => {
    //validate schedule times

    setHelperText(null);

    if (isRegularMode && selectedDays.length === 0) {
      setHelperText('Should select one days for regular mode');
    }

    if (isNaN(selectedInitTimeDate)) {
      setHelperText('Start time is not correct');
    }

    if (isNaN(selectedTimeDuration)) {
      setHelperText('Time duration is not correct');
    }

    if (minInitTimeDate > selectedInitTimeDate && selectedDays.length === 0) {
      setHelperText('Start time less then minimal available time');
    }

    if (selectedTimeDuration < 1) {
      setHelperText('Time duration less then minimal available time');
    }
  };

  getTimeLeft = (nowDate: Date, draftEndDate: Date): number => {
    const deltaDate = draftEndDate.getTime() - nowDate.getTime();
    return Math.ceil(deltaDate / (1000 * 60));
  };

  sortHardwareDevicesDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    hardwareDownstreamDataList: DeviceDataType[],
  ): DeviceDataType[] => {
    //sort devices
    return hardwareDownstreamDataList.sort((a, b) => {
      if (utils_instance.parseDeviceData(a, keySort) > utils_instance.parseDeviceData(b, keySort)) {
        return directionSort;
      } else if (keySort === sortKeyEnum.nickname) {
        return utils_instance.sortHardwareDataListByNickname(a, b, directionSort);
      } else {
        return directionSort * -1;
      }
    });
  };

  sortElevatorsDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    FloorGroupMainDataList: FloorGroupMainDataType[],
  ): FloorGroupMainDataType[] => {

    const newKeySort = keySort as keyof FloorGroupMainDataType;
    //sort devices
    return FloorGroupMainDataList.sort((a, b) => {
      if (keySort === sortKeyEnum.nickname) {
        return utils_instance.sortHardwareDataListByNickname(a, b, directionSort);
      }
      else {
        if (a[newKeySort] > b[newKeySort]) {
          return directionSort;
        } else {
          return directionSort * -1;
        }
      }
    });
  };

  sortHardwareDownstreamDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    hardwareDownstreamDataList: HardwareCommonDataType[],
  ): HardwareCommonDataType[] => {
    //sort hardware downstream
    const newKeySort = keySort as keyof HardwareCommonDataType;

    return hardwareDownstreamDataList.sort((a, b) => {
      if (keySort === sortKeyEnum.Status) {
        if (a.Status.StatusName > b.Status.StatusName) {
          return directionSort;
        } else {
          return directionSort * -1;
        }
      } else if (keySort === sortKeyEnum.nickname) {
        return utils_instance.sortHardwareDataListByNickname(a, b, directionSort);
      } else {
        if (a[newKeySort]! > b[newKeySort]!) {
          return directionSort;
        } else {
          return directionSort * -1;
        }
      }
    });
  };

  sortHardwareControllersDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    hardwareControllersDataList: HardwareControllerData[],
  ): HardwareControllerData[] => {
    //sort hardware controllers
    const newKeySort = keySort as keyof HardwareCommonDataType;

    if (!newKeySort) {
      return hardwareControllersDataList;
    }

    return hardwareControllersDataList.sort((a, b) => {
      if (keySort === sortKeyEnum.Status) {
        if (a.Status.StatusName > b.Status.StatusName) {
          return directionSort;
        } else {
          return directionSort * -1;
        }
      } else if (keySort === sortKeyEnum.nickname) {
        return utils_instance.sortHardwareDataListByNickname(a, b, directionSort);
      } else {
        if (a[newKeySort]! > b[newKeySort]!) {
          return directionSort;
        } else {
          return directionSort * -1;
        }
      }
    });
  };

  sortCountingAreasDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    countingAreasDataList: CountingAreaItem[],
  ): CountingAreaItem[] => {
    //sort hardware countingAreas
    const newKeySort = keySort as keyof CountingAreaItem;

    if (!newKeySort) {
      return countingAreasDataList;
    }

    return countingAreasDataList.sort((a, b) => {
      if(keySort === sortKeyEnum.in){
        return a.countPersonIn > b.countPersonIn ? directionSort : directionSort * -1;
      } else if (keySort === sortKeyEnum.out) {
        return a.countPersonOut > b.countPersonOut ? directionSort : directionSort * -1;
      } else if (keySort === sortKeyEnum.name) {
        return a.name > b.name ? directionSort : directionSort * -1;
      }else if (keySort === sortKeyEnum.people) {
        return a.countPerson > b.countPerson ? directionSort : directionSort * -1;
      } else {
        return a.name > b.name ? directionSort : directionSort * -1;
      }
    });
  };

  sortHardwareReadersDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    hardwareReadersDataList: HardwareReaderDataType[],
  ): HardwareReaderDataType[] => {
    //sort hardware readers
    const newKeySort = keySort as keyof HardwareReaderDataType;

    return hardwareReadersDataList.sort((a, b) => {
      if (keySort === sortKeyEnum.Status) {
        return utils_instance.sortHardwareReadersDataListByStatus(a, b, directionSort);
      } else if (keySort === sortKeyEnum.nickname) {
        return utils_instance.sortHardwareDataListByNickname(a, b, directionSort);
      } else {
        if (a[newKeySort]! > b[newKeySort]!) {
          return directionSort;
        } else {
          return directionSort * -1;
        }
      }
    });
  };

  sortInstancesDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    instancesList: InstanceExtendedPublicDataType[],
  ): InstanceExtendedPublicDataType[] => {
    const newKeySort = keySort as keyof InstanceExtendedPublicDataType;

    return instancesList.sort((a, b) => {
      if (newKeySort === 'ip_address') {
        return directionSort;
      }
      if (a[newKeySort]! > b[newKeySort]!) {
        return directionSort;
      } else {
        return directionSort * -1;
      }
    });
  };

  sortHardwareReadersDataListByStatus = (a: any, b: any, directionSort: number): number => {
    //sort hardware readers by status
    //it's need for sortable DoorHeldOpen reader before NonDoorHeldOpen reader
    if (a.Status.DoorHeldOpen < b.Status.DoorHeldOpen) {
      return directionSort;
    }
    if (a.Status.DoorHeldOpen > b.Status.DoorHeldOpen) {
      return directionSort * -1;
    }

    if (a.Status.StatusName > b.Status.StatusName) {
      return directionSort;
    }
    if (a.Status.StatusName < b.Status.StatusName) {
      return directionSort * -1;
    }

    return 0;
  };

  sortHardwareDataListByNickname = (a: any, b: any, directionSort: number): number => {
    if (!a.NicknameData && !b.NicknameData) {
      return 0;
    }

    if (!a.NicknameData && b.NicknameData.nickname) {
      return directionSort;
    }

    if (a.NicknameData.nickname && !b.NicknameData) {
      return directionSort * -1;
    }

    if (a.NicknameData.nickname > b.NicknameData.nickname) {
      return directionSort;
    }

    if (a.NicknameData.nickname < b.NicknameData.nickname) {
      return directionSort * -1;
    }

    return 0;
  };

  sortHardwareFloorGroupsDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    floorGroupsDataList: FloorGroupMainDataType[],
  ): FloorGroupMainDataType[] => {
    //sort hardware FloorGroups
    const newKeySort = keySort as keyof FloorGroupMainDataType;

    if (!newKeySort) {
      return floorGroupsDataList;
    }

    return floorGroupsDataList.sort((a, b) => {
      if (a[newKeySort] > b[newKeySort]) {
        return directionSort;
      } else {
        return directionSort * -1;
      }
    });
  };

  sortInstanceGroupsDataList = (
    keySort: sortKeyEnum,
    directionSort: number,
    instanceGroupsDataList: InstanceGroupListType,
  ): InstanceGroup[] => {
    //sort instances groups data list
    const newKeySort = keySort as keyof InstanceGroup;

    if (!newKeySort) {
      return instanceGroupsDataList.instance_groups;
    }

    return instanceGroupsDataList.instance_groups.sort((a, b) => {
      if (a[newKeySort] > b[newKeySort]) {
        return directionSort;
      } else {
        return directionSort * -1;
      }
    });
  };

  validateParams = (
    platform: string,
    serverId: number,
    instanceId: string,
    instancesList: InstancePublicDataType[],
  ): InstancePublicDataType | undefined => {
    //need for validate instance in instances list
    return instancesList.find(
      (instance: InstancePublicDataType) =>
        instance.server_id === serverId &&
        instance.instance_id === instanceId &&
        instance.platform === platform,
    );
  };

  fillMainMenu = (userInfo: UserPublicDataType): navigationGroupType[] => {
    //need for fill main menu in side nav
    const draftMenu = [];
    const draftBuildingMenu = { ...this.buildingMenu };
    const draftSecurityMenu = { ...this.securityMenu };
    const draftObliteratorMenu = { ...this.obliteratorMenu };
    const draftObserverMenu = { ...this.observerMenu };

    if (userInfo.user_permissions.controller_receive_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.controllerSubMenu];
    }

    if (userInfo.user_permissions.counting_areas_receive_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.CountingAreasSubMenu];
    }

    //todo change permissio
    // if (userInfo.user_permissions.holiday_permission) {
    if (userInfo.user_permissions.counting_areas_receive_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.HolidaySubMenu];
    }

    if (userInfo.user_permissions.downstream_receive_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.downstreamSubMenu];
    }

    if (userInfo.user_permissions.reader_receive_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.readerSubMenu];
    }

    if (userInfo.user_permissions.camera_info_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.cameraSubMenu];
    }

    if (userInfo.user_permissions.elevator_receive_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.elevatorSubMenu];
    }

    if (userInfo.user_permissions.intercom_info_permission) {
      draftBuildingMenu.items = [...draftBuildingMenu.items, this.intercomSubMenu];
    }

    if (draftBuildingMenu.items.length > 0) {
      draftMenu.push(draftBuildingMenu);
    }

    if (userInfo.user_permissions.user_logs_permission) {
      draftSecurityMenu.items = [...draftSecurityMenu.items, this.userLogsSubMenu];
    }

    if (userInfo.user_permissions.analytics_permission) {
      draftSecurityMenu.items = [...draftSecurityMenu.items, this.analyticSubMenu];
    }

    draftMenu.push(draftSecurityMenu);

    if (userInfo.user_permissions.obliterate_user_permission) {
      draftObliteratorMenu.items = [...draftObliteratorMenu.items, this.obliterateUserSubMenu];
    }

    draftMenu.push(draftObliteratorMenu);

    draftObserverMenu.items = [...draftObserverMenu.items, this.extendedInfoSubMenu];
    if (userInfo.user_permissions.instance_group_permission){
      draftObserverMenu.items = [...draftObserverMenu.items, this.InstanceGroupSubMenu];
    }
    draftMenu.push(draftObserverMenu);

    return draftMenu;
  };

  parseAvailableModes = (
    user_permissions: UserPermissionsType,
    platform: string,
    groupId?: number
  ): scheduleModeType[] => {
    //need for parse available modes for schedule
    const availableModeData =
        groupId
          ? { 5: 'Schedule: Unlock', 8: 'Schedule: Lock' }
          : user_permissions.schedule_mode_available_dict[platform];

    const availableModeList = [];
    for (const key in availableModeData) {
      availableModeList.push({
        key,
        value: availableModeData[key],
      });
    }
    return availableModeList;
  };

  getCurrentBuildingTime = (timezone: string): Date => {
    //get current time for select building by timezone
    const localeTime = new Date();
    const buildingTime = localeTime.toLocaleString('en-US', {
      timeZone: timezone,
    });
    return new Date(buildingTime);
  };

  checkIsValidToken = (token: string, cb: () => void) => {
    const decode: {
      exp: number;
    } = jwt_decode(token);
    if (decode.exp < +new Date() / 1000) {
      cb();
    }
  };

  getVisitChanges = (current: number, previous: number) => {
    if (current - previous === 0) {
      return {
        visitNumber: current,
        percentageDiff: '0%',
        data: [0, 100],
      };
    }

    if (current - previous > 0) {
      return {
        visitNumber: current,
        percentageDiff:
          previous === 0 ? '+' + 100 + '%' : '+' + ((current / previous) * 100).toFixed(2) + '%',
        data: [100, 0],
      };
    }

    return {
      visitNumber: current,
      percentageDiff:
        current === 0 ? -100 + '%' : (-100 + (current / previous) * 100).toFixed(2) + '%',
      data: [(current * 100) / previous, 100 - (current * 100) / previous],
    };
  };

  buildingMenu: navigationGroupType = {
    label: 'Building Panel',
    icon: icon_building,
    items: [],
  };

  securityMenu: navigationGroupType = {
    label: 'Security Panel',
    icon: icon_admin_panel,
    items: [],
  };

  obliteratorMenu: navigationGroupType = {
    label: 'Obliterator Panel',
    icon: icon_obliterator,
    items: [],
  };

  observerMenu: navigationGroupType = {
    label: 'Observer Panel',
    icon: icon_observer,
    items: [],
  };

  readerSubMenu: navigationItem = {
    content: 'Readers',
    path: PathNames.readers,
    isInstanceRequired: true,
  };

  cameraSubMenu: navigationItem = {
    content: 'Cameras',
    path: PathNames.cameras,
    isInstanceRequired: true,
  };

  intercomSubMenu: navigationItem = {
    content: 'Intercoms',
    path: PathNames.intercoms,
    isInstanceRequired: true,
  };

  controllerSubMenu: navigationItem = {
    content: 'Controllers',
    path: PathNames.controllers,
    isInstanceRequired: true,
  };

  downstreamSubMenu: navigationItem = {
    content: 'Downstreams',
    path: PathNames.downstreams,
    isInstanceRequired: true,
  };

  elevatorSubMenu: navigationItem = {
    content: 'Elevators',
    path: PathNames.elevators,
    isInstanceRequired: true,
  };

  userLogsSubMenu: navigationItem = {
    content: 'User Logs',
    path: PathNames.user_log,
    isInstanceRequired: true,
  };

  analyticSubMenu: navigationItem = {
    content: 'Analytic',
    path: PathNames.analytic,
    isInstanceRequired: true,
  };

  obliterateUserSubMenu: navigationItem = {
    content: 'Users',
    path: PathNames.hardware_users,
    isInstanceRequired: false,
  };

  extendedInfoSubMenu: navigationItem = {
    content: 'Instances List',
    path: PathNames.instances_list,
    isInstanceRequired: false,
  };

  InstanceGroupSubMenu: navigationItem = {
    content: 'Instances Groups',
    path: PathNames.instance_groups,
    isInstanceRequired: false,
  };

  CountingAreasSubMenu: navigationItem = {
    content: 'Counting Areas',
    path: PathNames.counting_areas,
    isInstanceRequired: true,
  };

  HolidaySubMenu: navigationItem = {
    content: 'Holiday',
    path: PathNames.holiday,
    isInstanceRequired: true,
  };

  readersActionsDescriptions: readersActionDescriptionsType = {
    [readersActionNamesEnum.cardOnly]: 'Place Reader in card only mode (keycard needed)',
    [readersActionNamesEnum.unlock]: 'Unlock Door (no keycard needed)',
    [readersActionNamesEnum.reset]: 'Reset All Holiday schedules. Turns readers to normal state',
    [readersActionNamesEnum.pulse]: 'Temporarily unlock Reader for 6 seconds',
    [readersActionNamesEnum.mode]: 'Add schedule to Reader',
    [readersActionNamesEnum.modeAll]: 'Add schedule to All Readers',
    [readersActionNamesEnum.cardOnlyAll]:
      'Place All Readers in card only mode (keycard needed)',
    [readersActionNamesEnum.resetAll]: 'Please All Readers back in normal schedule',
    [readersActionNamesEnum.logsList]: 'Check logs for this reader',
  };

  readersStatusDescriptions: readersStatusDescriptionsType = {
    [readerStatusNamesEnum.NoAccessMethod]:
      'No action is required to be granted access during this schedule',
    [readerStatusNamesEnum.CardOnly]: 'Reader will exclusively grant access to a card',
    [readerStatusNamesEnum.PinOnly]: 'Reader will exclusively grant access to a PIN',
    [readerStatusNamesEnum.CardAndPin]: 'The reader requires a card and PIN to grant access',
    [readerStatusNamesEnum.CardOrPin]: 'Only a card of PIN is required to grant access',
    [readerStatusNamesEnum.FacilityCodeOnly]:
      'Only the facility code is required to receive an access granted event',
    [readerStatusNamesEnum.Unlocked]: 'Reader is unlocked.  No keycard is needed to open the door',
    [readerStatusNamesEnum.LockedDown]: 'The reader will be locked down during its online state',

    [readerStatusNamesEnum.ClosedCardRead]: 'Keycard was presented at reader but was not opened',
    [readerStatusNamesEnum.DelayOnEgress]:
      'A request-to-exit has occurred but the reader has not been opened',
    [readerStatusNamesEnum.Disabled]: '\'Reader has been temporarily removed from the system\'s control via a Disable reader action\'', // eslint-disable-line
    [readerStatusNamesEnum.DogOnNextExit]: 'Reader (which has an ENGAGE RU exit device) has been changed to this state manually by a Dog the reader action or automatically by a scheduled action or a reader group time spec', // eslint-disable-line
    [readerStatusNamesEnum.ExtendedUnlock]: 'Reader is unlocked. No keycard is needed to open the doors', // eslint-disable-line
    [readerStatusNamesEnum.Forced]: 'Reader was opened without a keycard',
    [readerStatusNamesEnum.Held]: 'Reader has been held open for an extended period of time',
    [readerStatusNamesEnum.Init]: 'Reader is initializing and is not ready for use. Check the configuration of outputs and other resources associated with the reader', // eslint-disable-line
    [readerStatusNamesEnum.Privacy]: '\'Reader\'s Schlage AD-400 lockset has been put into Privacy mode via the AD-400 Lockset Mode option\'', // eslint-disable-line
    [readerStatusNamesEnum.Ready]: 'Reader is ready for use',
    [readerStatusNamesEnum.ShuntedClosed]: 'Reader attempted to be opened for 6 seconds but failed',
    [readerStatusNamesEnum.ShuntedOpen]: 'Reader has been opened for 6 seconds',

    [readerStatusNamesEnum.Unknown]: 'Reader has unknown status',
  };

  controllersStatusDescriptions: controllersStatusDescriptionsType = {
    [controllerStatusNamesEnum.Online]: 'Controller is operational',
    [controllerStatusNamesEnum.Offline]: 'Controller is not operational',
    [controllerStatusNamesEnum.Unknown]: 'Controller has unknown status',
    [controllerStatusNamesEnum.Connected]: 'Controller is operational',
    [controllerStatusNamesEnum.Tampered]: 'Controller is tampered',
    [controllerStatusNamesEnum.NotEnabled]: 'Controller is not operational',
  };

  downstreamStatusDescriptions: downstreamStatusDescriptionsType = {
    [downstreamStatusNamesEnum.Online]: 'Downstream is operational',
    [downstreamStatusNamesEnum.Offline]: 'Downstream is not operational',
    [downstreamStatusNamesEnum.Unknown]: 'Downstream has unknown status',
  };

  camerasStatusDescriptions: deviceStatusDescriptionsType = {
    [deviceStatusNamesEnum.Online]: 'Camera is operational',
    [deviceStatusNamesEnum.Offline]: 'Camera is not operational',
    [deviceStatusNamesEnum.Unknown]: 'Camera has unknown status',
  };

  intercomStatusDescriptions: deviceStatusDescriptionsType = {
    [intercomStatusNamesEnum.Online]: 'Intercom is operational',
    [intercomStatusNamesEnum.Offline]: 'Intercom is not operational',
    [intercomStatusNamesEnum.Unknown]: 'Intercom has unknown status',
  };

  countingAreasStatusDescriptions: countingAreasStatusDescriptionsType = {
    [countingAreasStatusNamesEnum.Rules]: 'Check rules for this area',
  };

  daysList = [
    {
      name: 'Monday',
      id: 0,
    },
    {
      name: 'Tuesday',
      id: 1,
    },
    {
      name: 'Wednesday',
      id: 2,
    },
    {
      name: 'Thursday',
      id: 3,
    },
    {
      name: 'Friday',
      id: 4,
    },
    {
      name: 'Saturday',
      id: 5,
    },
    {
      name: 'Sunday',
      id: 6,
    },
  ];

  setDateTimeMode(date: number,
    timeOfDayMode: HolidayTriggerTimeModeEnum,
    currentInstanceData:  InstancePublicDataType
  ): number{
    const currentBuildingTime = this.getCurrentBuildingTime(currentInstanceData.timezone);
    const delta = currentBuildingTime.getTime() - Date.now();
    switch (timeOfDayMode) {
    case HolidayTriggerTimeModeEnum.rightNow:
      return Math.floor(date / 1000);
    case HolidayTriggerTimeModeEnum.midnight:
      const dateNew = new Date(date);
      dateNew.setHours(0,0,0,0);
      // Set time to midnight
      return Math.floor((dateNew.getTime() - delta) / 1000);
    default:
      throw new Error('Invalid holiday mode');
    }
  }

  setDateTimeModeForToday(
    date: Date,
    timeOfDayMode: HolidayTriggerTimeModeEnum,
    selectedTimeDuration: number,
    currentInstanceData: InstancePublicDataType,
  ) {
    const currentTime = utils_instance.getCurrentBuildingTime(currentInstanceData.timezone);
    switch (timeOfDayMode) {
    case HolidayTriggerTimeModeEnum.rightNow:
      return {
        init_date: Math.floor(Date.now() / 1000),
        minutes: selectedTimeDuration,
      };
    case HolidayTriggerTimeModeEnum.midnight:
      const midnight = new Date(currentTime);
      midnight.setHours(0, 0, 0, 0);
      const difInMinutes = (currentTime.getTime() - midnight.getTime()) / (60 * 1000);
      const timeLeftMinutes = Math.floor(selectedTimeDuration - difInMinutes);
      return {
        init_date: Math.floor(Date.now() / 1000),
        minutes: timeLeftMinutes > 0 ? timeLeftMinutes : 1,
      };

    default:
      throw new Error('Invalid holiday mode');
    }
  }

  getDataString(
    selectedDayMode: HolidayTriggerDayModeEnum,
    timeOfDayMode: HolidayTriggerTimeModeEnum,
    currentInstanceData: InstancePublicDataType,
    selectedTimeDuration: number,
    selectedInitTimeDate: number

  ) {
    const currentDate = new Date();

    switch (selectedDayMode) {
    case HolidayTriggerDayModeEnum.today:
      return this.setDateTimeModeForToday(
        currentDate,
        timeOfDayMode,
        selectedTimeDuration,
        currentInstanceData,
      );
    case HolidayTriggerDayModeEnum.tomorrow:
      const tomorrowDate = Date.now() + 24 * 60 * 60 * 1000;
      return {
        init_date: this.setDateTimeMode(tomorrowDate, timeOfDayMode, currentInstanceData),
        minutes: selectedTimeDuration,
      };
    case HolidayTriggerDayModeEnum.customRang:
      return {
        init_date: selectedInitTimeDate,
        minutes: selectedTimeDuration,
      };
    default:
      throw new Error('Invalid holiday mode');
    }
  }

  chartDataInitialState = {
    datasets: [
      {
        data: [],
        backgroundColor: ['rgb(46, 61, 107)', 'rgb(225, 228, 234)'],
        cutout: '84%',
        display: true,
        border: '1px solid #D1D6DC',
        borderColor: '#D1D6DC',
        circumference: 180,
        rotation: 270,
      },
    ],
  };

  colorList = [
    '#7B56DB',
    '#009CEB',
    '#00CDAF',
    '#DD9900',
    '#FF677B',
    '#CB2196',
    '#813193',
    '#0051B5',
    '#008C80',
    '#99B100',
    '#FFA476',
    '#FF6ACE',
    '#AE8CFF',
    '#00689D',
    '#00490A',
    '#465D00',
    '#9D6300',
    '#F6540B',
    '#FF969E',
    '#E47BFE',
    '#b1110f',
    '#d90011',
    '#f08e48',
    '#f8bf4d',
    '#5ea859',
    '#34828d',
    '#217bd2',
    '#1e87c2',
    '#6850a2',
    '#fffd50',
  ];

  cameraColors = ['#4CAF50', '#DB4437', '#D1D6DC'];

  chartDataCamerasInitialState = {
    labels: ['Online', 'Offline', 'Unknown'],
    datasets: [
      {
        data: [0, 0, 100],
        backgroundColor: this.cameraColors,
        cutout: '84%',
        display: true,
        border: '1px solid #D1D6DC',
        borderColor: '#D1D6DC',
      },
    ],
  };

  chartDataActionInitialState = {
    labels: ['No actions'],
    datasets: [
      {
        data: [0, 100],
        backgroundColor: ['#D1D6DC'],
        cutout: '84%',
        display: true,
        border: '1px solid #D1D6DC',
        borderColor: '#D1D6DC',
        circumference: 360,
        rotation: 0,
      },
    ],
  };
  infoForVisitsChartInitialState = {
    data: [0, 100],
  };

  chartOptions: ChartOptions<'doughnut'> = {
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
    },
  };

  halfChartOptions: ChartOptions<'doughnut'> = {
    aspectRatio: 2,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
      },
    },
  };

  getCameraByMac = (macAddress: string, cameraList: DeviceDataType[]) => {
    return cameraList.find((camera) => camera.attributes.mac_address === macAddress);
  };

  RoleLabels = [
    {
      label: 'All Roles',
      id: 0,
    },
    {
      label: 'Obliterator',
      id: 1,
    },
    {
      label: 'Security',
      id: 2,
    },
    {
      label: 'Engineer',
      id: 3,
    },
    {
      label: 'Commons Support',
      id: 5,
    },
    {
      label: 'Management',
      id: 4,
    },
    {
      label: 'Secc it',
      id: 6,
    },
    {
      label: 'Secc events',
      id: 7,
    },
  ];
  
  parseDetailString(
    value: string
  ):[string, string][]{
    const deviceStatusObject = JSON.parse(value.replace(/'/g, '"'));
    return Object.entries(deviceStatusObject);
  }

  parseDateList(schedule:  ScheduleMainDataType[]) {
    return schedule.map((el) => {
      const type = el.is_from_holiday_schedule
        ? 'Holiday schedule'
        : el.is_from_mode_all_schedule
          ? 'Mode all Schedule'
          : el.days_of_week
            ? 'Regular Schedule'
            : 'Simple Schedule';
      return {
        ...el,
        mode: el.mode.replace('Schedule: ', ''),
        days_of_week: el.days_of_week ? el.days_of_week.map((days) => DaysOfWeek[+days]) : null,
        type: type,
        schedule_visible_init_date:
            this.parseFullDateForSchedule(
              new Date(el.init_date.substring(0, el.init_date.length - 6))),
        schedule_visible_end_date:
            this.parseFullDateForSchedule(
              new Date(el.end_date.substring(0, el.end_date.length - 6))),
      };
    });
  }
  convertLocalToUTC(timestamp: number) {
    const date = new Date(timestamp);
    return Date.UTC(date.getUTCFullYear(), date.getUTCMonth(),
      date.getDate(), 0, 0, 0, 0);
  }
  convertUTCToLocal(timestamp: number) {
    const date = new Date(timestamp);

    return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
  }
}

export const utils_instance = new Utils();
