import React, { useEffect, useState } from 'react';
import { websocketEventEnum, websocketPageEnum } from '../types/websocketTypes';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../store/store';
import {
  addNotificationAC,
  blockSocketObjectAC,
  clearSocketObjectAC,
  setIsSocketConnectedAC,
  setSocketObjectAC,
} from '../store/appReducer';
import { updateReadersStatusesAC } from '../store/readersReducer';
import {
  setCurrentlySchedulesDataAC,
  setHoldSchedulesDataAC,
  setTemporarySchedulesDataAC,
} from '../store/scheduleReducer';
import { setCameraStatusDataAC } from '../store/camerasReducer';
import { notificationEnum } from '../types/appTypes';
import { useLocation } from 'react-router-dom';
import { HardwareReaderDataType } from '../types/readersTypes';
import { DeviceDataType } from '../types/deviceInfoTypes';
import { HardwareUserDataType, ServerUserDataType } from '../types/HardwareUsersTypes';
import {
  addHardwareUserServerAC,
  decrementServersPublicCountAC,
} from '../store/hardwareUsersReducer';
import { utils_instance } from '../utils/Utils';
import { useAuth0 } from '@auth0/auth0-react';
import { setIntercomStatusDataAC } from '../store/intercomsReducer';
import { useCurrentIp } from '../store/selectors/useCurrentIp';
import { useCurrentInstanceData } from '../store/selectors/useCurrentInstanceData';
import { InstanceGroupDetailResponseType } from '../types/instancesGroupsTypes';
import { setInstanceGroupSchedulesAC } from '../store/instanceGroupsReducer';
import { CountingAreaItem, WSCountingAreaItemRes } from '../types/countingAreasTypes';
import {
  setCountingAreasCountDataAC, setCountingAreasShouldUpdateAC,
  updateCountingAreasAccessLevelDataAC, updateCountingAreasActiveRuleDataAC
} from '../store/countingAreasReducer';

const WebSocketItem: React.FC = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { logout } = useAuth0();

  const token = useSelector<AppRootStateType, string | null>((state) => state.auth.token);

  const currentIp = useCurrentIp();
  const currentInstanceData = useCurrentInstanceData();

  const [instanceGroupScheduleInterval, setInstanceGroupScheduleInterval] =
      useState<ReturnType<typeof setInterval> | null>(null);
  const [webSocketType, setWebSocketType] = useState<websocketPageEnum>(websocketPageEnum.other);

  const [readersStatusInterval, setReadersStatusInterval] =
      useState<ReturnType<typeof setInterval> | null>(null);
  const [readersSchedulesInterval, setReadersSchedulesInterval] =
      useState<ReturnType<typeof setInterval> | null>(null);

  const socket = useSelector<AppRootStateType, WebSocket | undefined>((state) => state.app.socket);
  const isSocketAllowed = useSelector<AppRootStateType, boolean>(
    (state) => state.app.isSocketAllowed,
  );
  const isSocketConnected = useSelector<AppRootStateType, boolean>(
    (state) => state.app.isSocketConnected,
  );

  const hardwareReadersDataList = useSelector<AppRootStateType, HardwareReaderDataType[]>(
    (state) => state.readers.hardwareReadersDataList,
  );

  const camerasDataList = useSelector<AppRootStateType, DeviceDataType[]>(
    (state) => state.cameras.camerasDataList,
  );
  const intercomsDataList = useSelector<AppRootStateType, DeviceDataType[]>(
    (state) => state.intercoms.intercomsDataList,
  );

  const hardwareUser = useSelector<AppRootStateType, HardwareUserDataType | null>(
    (state) => state.hardwareUsers.hardwareUser,
  );
  const serversUserList = useSelector<AppRootStateType, ServerUserDataType[]>(
    (state) => state.hardwareUsers.serversUserList,
  );

  const currentInstanceGroup =
      useSelector<AppRootStateType, InstanceGroupDetailResponseType | null>(
        (state) => state.instanceGroups.instanceGroupData,
      );

  const countingAreas = useSelector<AppRootStateType, CountingAreaItem[]>(
    state => state.counting_areas.countingAreas);
  const countingAreasShouldUpdate = useSelector<AppRootStateType, boolean>(
    state => state.counting_areas.countingAreasShouldUpdate);

  useEffect(() => {
    return () => {
      clearInterval(instanceGroupScheduleInterval as ReturnType<typeof setInterval>);
    };
  }, [webSocketType, instanceGroupScheduleInterval, currentInstanceData, currentInstanceGroup]);

  useEffect(() => {
    return () => {
      clearInterval(readersStatusInterval as ReturnType<typeof setInterval>);
      clearInterval(readersSchedulesInterval as ReturnType<typeof setInterval>);
    };
  }, [
    webSocketType,
    readersStatusInterval,
    readersSchedulesInterval,
    currentInstanceData
  ]);

  const myLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  useEffect(() => {
    if (socket) {
      socket.onopen = () => {
      };

      socket.onmessage = (event) => {
        applyUpdates(JSON.parse(event.data));
      };

      socket.onclose = () => {
        dispatch(clearSocketObjectAC());
      };

      socket.onerror = () => {
        dispatch(clearSocketObjectAC());
      };
    } else {
      if (token) {
        utils_instance.checkIsValidToken(token, myLogout);
        isSocketAllowed && dispatch(setSocketObjectAC(token));
      }
    }
  }, [socket]);

  useEffect(() => {
    if (socket?.readyState === 1) {
      dispatch(setIsSocketConnectedAC(true));
    }
  }, [socket?.readyState]);

  const applyUpdates = (data: any) => {
    switch (data.event) {
    case websocketEventEnum.readers_status: {
      if (!currentInstanceData) {
        return null;
      }
      dispatch(updateReadersStatusesAC(data.statuses, data.statusesHashSum));
      break;
    }
    case websocketEventEnum.schedule: {
      if (!currentInstanceData) {
        return null;
      }
      dispatch(
        setTemporarySchedulesDataAC(
          data.active_schedule_list,
          currentInstanceData.timezone,
          data.scheduleHashSum
        ),
      );
      dispatch(
        setCurrentlySchedulesDataAC(
          data.currently_schedule_list,
          currentInstanceData.timezone,
          data.scheduleHashSum
        ),
      );
      dispatch(
        setHoldSchedulesDataAC(
          data.hold_schedule_list,
          currentInstanceData.timezone,
          data.scheduleHashSum
        ),
      );
      break;
    }
    case websocketEventEnum.camera_status: {
      dispatch(setCameraStatusDataAC(data.mac_address, data.status));
      break;
    }
    case websocketEventEnum.intercom_status: {
      dispatch(setIntercomStatusDataAC(data.ip_address, data.status));
      break;
    }
    case websocketEventEnum.hardware_user_server: {
      dispatch(
        addHardwareUserServerAC(
          data.server_name,
          data.user_key,
          data.is_exist,
        ),
      );
      dispatch(decrementServersPublicCountAC(data.user_key));
      break;
    }
    case websocketEventEnum.instance_group_schedules: {
      dispatch(setInstanceGroupSchedulesAC(data.schedules));
      break;
    }
    case websocketEventEnum.disconnect_message: {
      dispatch(
        addNotificationAC(
          notificationEnum.error,
          'Token expired. Please, Log in again',
        ),
      );
      dispatch(blockSocketObjectAC());
      break;
    }
    case websocketEventEnum.people_count: {
      const response: WSCountingAreaItemRes = data;
      const { countPerson, countPersonOut, countPersonIn } = response.count_people;
      const countingArea: CountingAreaItem = {
        id: response.counting_area_id,
        name: '',
        isFavorite: false,
        countPerson,
        countPersonOut,
        countPersonIn,
        isShouldUpdateCount: false,
      };
      dispatch(setCountingAreasCountDataAC(countingArea));
      break;
    }
    case websocketEventEnum.access_level: {
      const { counting_area_id, access_level } = data;
      dispatch(updateCountingAreasAccessLevelDataAC(access_level, counting_area_id));
      break;
    }
    case websocketEventEnum.alert_status: {
      const { counting_area_id, active_rule } = data;
      dispatch(updateCountingAreasActiveRuleDataAC(active_rule, counting_area_id));
      break;
    }
    default:
      break;
    }
  };

  const loadReadersStatuses = () => {
    if (!currentInstanceData) return null;
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    if (hardwareReadersDataList.length > 0) {
      const message = {
        event: websocketEventEnum.readers_status,
        ip_address: currentIp,
        instance: currentInstanceData.instance_id,
        server: currentInstanceData.server_id,
        platform: currentInstanceData.platform,
      };
      socket.send(JSON.stringify(message));
    }
  };

  const loadInstanceGroupSchedules = () => {
    if (!currentInstanceData) {
      return null;
    }
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    const message = {
      event: websocketEventEnum.instance_group_schedules,
      instanceGroupId: currentInstanceGroup?.id
    };
    socket.send(JSON.stringify(message));
  };

  const loadReadersSchedules = () => {
    if (!currentInstanceData) {
      return null;
    }
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    if (hardwareReadersDataList.length > 0) {
      const message = {
        event: websocketEventEnum.schedule,
        ip_address: currentIp,
        instance: currentInstanceData.instance_id,
        server: currentInstanceData.server_id,
        platform: currentInstanceData.platform,
      };
      socket.send(JSON.stringify(message));
    }
  };

  const loadCameraStatus = (mac_address: string) => {
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    const message = {
      event: websocketEventEnum.camera_status,
      mac_address,
    };
    socket?.send(JSON.stringify(message));
  };

  const loadIntercomStatus = (ip_address: string) => {
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    const message = {
      event: websocketEventEnum.intercom_status,
      ip_address,
    };
    socket?.send(JSON.stringify(message));
  };

  const loadHardwareUserServer = (hardwareServer: ServerUserDataType) => {
    if (!hardwareUser) {
      return null;
    }
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    const message = {
      event: websocketEventEnum.hardware_user_server,
      server_id: hardwareServer.server_id,
      server_name: hardwareServer.server_name,
      platform: hardwareServer.platform,
      user_key: hardwareUser.user_key,
    };
    try {
      socket?.send(JSON.stringify(message));
    } catch (e: any) {
      dispatch(
        addNotificationAC(notificationEnum.error, `load data failed - ${e.response?.data?.detail}`),
      );
    }
  };

  const loadCountingAreasPeopleCount = (counting_area_id: string) => {
    if (!currentInstanceData) {
      return null;
    }
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    const message = {
      event: websocketEventEnum.people_count,
      ip_address: currentIp,
      instance: currentInstanceData.instance_id,
      server: currentInstanceData.server_id,
      platform: currentInstanceData.platform,
      counting_area_id,
    };
    socket?.send(JSON.stringify(message));
  };

  const loadCountingAreasAccessLevel = (counting_area_id: string) => {
    if (!currentInstanceData) {
      return null;
    }
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    const message = {
      event: websocketEventEnum.access_level,
      ip_address: currentIp,
      instance: currentInstanceData.instance_id,
      server: currentInstanceData.server_id,
      platform: currentInstanceData.platform,
      counting_area_id,
    };

    socket?.send(JSON.stringify(message));
  };

  const loadCountingAreasAlertsStatus = (counting_area_id: string) => {
    if (!currentInstanceData) {
      return null;
    }
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    const message = {
      event: websocketEventEnum.alert_status,
      ip_address: currentIp,
      instance: currentInstanceData.instance_id,
      server: currentInstanceData.server_id,
      platform: currentInstanceData.platform,
      counting_area_id,
    };

    socket?.send(JSON.stringify(message));
  };

  const createRequests = () => {
    if (!socket) return null;
    if(socket.readyState !== 1) return null;

    if (!isSocketAllowed) {
      return null;
    }
    if (!isSocketConnected) {
      return null;
    }
    switch (webSocketType) {
    case websocketPageEnum.readers: {
      setReadersStatusInterval(setInterval(loadReadersStatuses, 20000));
      setReadersSchedulesInterval(setInterval(loadReadersSchedules, 30000));
      break;
    }
    case websocketPageEnum.cameras: {
      camerasDataList.find((camera) => {
        if (!camera.isCheckedStatus) {
          loadCameraStatus(camera.attributes.mac_address);
          return camera;
        }
      });
      break;
    }
    case websocketPageEnum.intercoms: {
      intercomsDataList.find((intercom) => {
        if (!intercom.isCheckedStatus) {
          loadIntercomStatus(intercom.attributes.ip_address);
          intercom.isCheckedStatus = true; // eslint-disable-line
          return intercom;
        }
      });
      break;
    }
    case websocketPageEnum.hardware_users: {
      serversUserList.find((hardwareServer) => {
        if (hardwareServer.is_exist === 0) {
          loadHardwareUserServer(hardwareServer);
          return hardwareServer;
        }
      });
      break;
    }
    case websocketPageEnum.instance_group_schedules: {
      setInstanceGroupScheduleInterval(setInterval( loadInstanceGroupSchedules, 10000));
      break;
    }
    case websocketPageEnum.counting_areas: {
      if(countingAreas.length > 0 && countingAreasShouldUpdate){
        countingAreas.forEach( item => {
          loadCountingAreasPeopleCount(item.id);
          loadCountingAreasAccessLevel(item.id);
          loadCountingAreasAlertsStatus(item.id);
          dispatch(setCountingAreasShouldUpdateAC(false));
        });
      }
      break;
    }
    case websocketPageEnum.other: {
      break;
    }
    default:
      break;
    }
  };

  const changeWebSocketType = () => {
    if (currentInstanceData) {
      switch (location.pathname) {
        case `/readers/platform/${currentInstanceData.platform}/server/${currentInstanceData?.server_id}/instance/${currentInstanceData?.instance_id}`: { // eslint-disable-line
        setWebSocketType(websocketPageEnum.readers);
        break;
      }
        case `/analytic/platform/${currentInstanceData?.platform}/server/${currentInstanceData?.server_id}/instance/${currentInstanceData?.instance_id}`: // eslint-disable-line
        case `/cameras/platform/${currentInstanceData.platform}/server/${currentInstanceData?.server_id}/instance/${currentInstanceData?.instance_id}`: { // eslint-disable-line
        setWebSocketType(websocketPageEnum.cameras);
        break;
      }
        case `/intercoms/platform/${currentInstanceData.platform}/server/${currentInstanceData?.server_id}/instance/${currentInstanceData?.instance_id}`: { // eslint-disable-line
        setWebSocketType(websocketPageEnum.intercoms);
        break;
      }
      case '/hardware_users':
      case '/hardware_users/':
        case `/hardware_users/platform/${currentInstanceData.platform}/server/${currentInstanceData?.server_id}/instance/${currentInstanceData?.instance_id}`: { // eslint-disable-line
        setWebSocketType(websocketPageEnum.hardware_users);
        break;
      }
      case `/instance_groups_action/instance/${currentInstanceGroup?.id}`:
        setWebSocketType(websocketPageEnum.instance_group_schedules);
        break;
      case `/counting_areas/platform/${currentInstanceData.platform}/server/${currentInstanceData?.server_id}/instance/${currentInstanceData?.instance_id}`: { // eslint-disable-line
        setWebSocketType(websocketPageEnum.counting_areas);
        break;
      }
      default:
        setWebSocketType(websocketPageEnum.other);
        break;
      }
    } else {
      switch (location.pathname) {
      case '/hardware_users':
      case '/hardware_users/': {
        setWebSocketType(websocketPageEnum.hardware_users);
        break;
      }
      default:
        setWebSocketType(websocketPageEnum.other);
        break;
      }
    }
  };

  useEffect(() => {
    createRequests();
  }, [
    webSocketType,
    socket,
    isSocketAllowed,
    isSocketConnected,
    currentInstanceData,
    currentIp,
    camerasDataList,
    intercomsDataList,
    hardwareReadersDataList,
    hardwareUser,
    serversUserList,
    currentInstanceGroup,
    countingAreas,
    countingAreasShouldUpdate
  ]);

  useEffect(() => {
    changeWebSocketType();
  }, [location.pathname]);

  return null;
};

export default WebSocketItem;
