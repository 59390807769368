import { DeviceDataType } from '../types/deviceInfoTypes';
import { sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { utils_instance } from '../utils/Utils';
import { analyticDataType } from '../types/analyticInfoTypes';
import { CameraStatusDataType } from '../types/cameraInfoTypes';

enum camerasReducerTypes {
  setCamerasDataList = 'cameras/setCamerasDataList',
  setSortCamerasData = 'cameras/setSortCamerasData',
  setCameraStatusData = 'cameras/setCameraStatusData',
}

export const setCamerasDataAC = (camerasDataList: DeviceDataType[]) => {
  return {
    type: camerasReducerTypes.setCamerasDataList,
    camerasDataList,
  } as const;
};

export const setSortCamerasDataAC = (keySort: sortKeyEnum) => {
  return {
    type: camerasReducerTypes.setSortCamerasData,
    keySort,
  } as const;
};

export const setCameraStatusDataAC = (
  mac_address: string,
  cameraStatusData: CameraStatusDataType,
) => {
  return {
    type: camerasReducerTypes.setCameraStatusData,
    mac_address,
    cameraStatusData,
  } as const;
};

export type setCamerasDataAT = ReturnType<typeof setCamerasDataAC>;
export type setCameraStatusDataAT = ReturnType<typeof setCameraStatusDataAC>;
export type setSortCamerasDataAT = ReturnType<typeof setSortCamerasDataAC>;

export type actionType =
    | setCamerasDataAT
    | setCameraStatusDataAT
    | setSortCamerasDataAT

type InitStateType = {
  camerasDataList: DeviceDataType[];
  sortInfo: sortInfoType;
  analyticData: analyticDataType;
};

export const initState: InitStateType = {
  camerasDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.FullName,
    directionSort: 1,
  },
  analyticData: {
    onlineCount: 0,
    offlineCount: 0,
    unknownCount: 0,
  },
};

export const camerasReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case camerasReducerTypes.setCamerasDataList: {
    const copyState = { ...state };

    const listFavorites: DeviceDataType[] = [];
    const listNotFavorites: DeviceDataType[] = [];

    copyState.analyticData.offlineCount = 0;
    copyState.analyticData.onlineCount = 0;

    action.camerasDataList.forEach((item) => {
      if (item.IsFavorite) {
        const newItem = state.camerasDataList.find((camera) => camera.id === item.id);
        if (newItem) {
          newItem.IsFavorite = true;
          newItem.NicknameData = item.NicknameData;
          listFavorites.push(newItem);
        } else {
          listFavorites.push(item);
        }
      } else {
        const newItem = state.camerasDataList.find((camera) => camera.id === item.id);
        if (newItem) {
          newItem.IsFavorite = false;
          newItem.NicknameData = item.NicknameData;
          listNotFavorites.push(newItem);
        } else {
          listNotFavorites.push(item);
        }
      }
    });

    const sortFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.camerasDataList = [...sortFavorites, ...sortNotFavorites];

    copyState.analyticData.unknownCount = copyState.camerasDataList.length;

    return copyState;
  }
  case camerasReducerTypes.setSortCamerasData: {
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
        state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    const listFavorites: DeviceDataType[] = [];
    const listNotFavorites: DeviceDataType[] = [];

    state.camerasDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.camerasDataList = [...sortFavorites, ...sortNotFavorites];
    copyState.sortInfo.keySort = action.keySort;

    return copyState;
  }
  case camerasReducerTypes.setCameraStatusData: {
    const copyState = { ...state };
    // copyState.analyticData.unknownCount -= copyState.analyticData.onlineCount + copyState.analyticData.offlineCount;
    const camera = utils_instance.getCameraByMac(
      action.cameraStatusData.mac,
      state.camerasDataList,
    );
    if (
      state.camerasDataList.length &&
        copyState.analyticData.unknownCount > 0 &&
        !camera?.isCheckedStatus
    ) {
      if (action.cameraStatusData.icmpping === 1) {
        copyState.analyticData.onlineCount++;
        copyState.analyticData.unknownCount--;
      } else if (action.cameraStatusData.icmpping === 0) {
        copyState.analyticData.offlineCount++;
        copyState.analyticData.unknownCount--;
      }
    }

    copyState.camerasDataList = state.camerasDataList.map((camera) => {
      if (camera.attributes.mac_address === action.mac_address) {
        return {
          ...camera,
          isCheckedStatus: true,
          attributes: {
            ...camera.attributes,
            address: action.cameraStatusData.address,
            building: action.cameraStatusData.building,
            host: action.cameraStatusData.host,
            hostid: action.cameraStatusData.hostid,
            hostname: action.cameraStatusData.hostname,
            icmpping: action.cameraStatusData.icmpping,
            lat: action.cameraStatusData.lat,
            lon: action.cameraStatusData.lon,
            model: action.cameraStatusData.model,
            region: action.cameraStatusData.region,
            serial: action.cameraStatusData.serial,
            software: action.cameraStatusData.software,
            territory: action.cameraStatusData.territory,
            vendor: action.cameraStatusData.vendor,
          },
        };
      } else {
        return camera;
      }
    });

    return copyState;
  }
  default: {
    return state;
  }
  }
};
