export enum downstreamStatusNamesEnum {
  Online = 'Online',
  Offline = 'Offline',

  Unknown = 'Unknown',
}

export type downstreamStatusDescriptionsType = {
  [key in downstreamStatusNamesEnum]: string;
};
