import React from 'react';
import { Auth0Provider } from '@auth0/auth0-react';
import config from '../config';

type propsType = {
  children: any;
};

const AuthProvider: React.FC<propsType> = (props) => {
  const { children } = props;

  return (
    <Auth0Provider
      domain={config.auth0Domain}
      clientId={config.auth0Client}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: config.auth0Audience,
      }}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProvider;
