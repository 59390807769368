import { DeviceDataType } from '../types/deviceInfoTypes';
import { sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { utils_instance } from '../utils/Utils';
import { IntercomStatusDataType } from '../types/IntercomTypes';

enum intercomsReducerTypes {
  setIntercomsDataList = 'cameras/setIntercomsDataList',
  setSortIntercomsData = 'cameras/setSortIntercomsData',
  setIntercomStatusData = 'cameras/setIntercomStatusData',
}

export const setIntercomsDataAC = (intercomsDataList: DeviceDataType[]) => {
  return {
    type: intercomsReducerTypes.setIntercomsDataList,
    intercomsDataList,
  } as const;
};

export const setSortIntercomsDataAC = (keySort: sortKeyEnum) => {
  return {
    type: intercomsReducerTypes.setSortIntercomsData,
    keySort,
  } as const;
};

export const setIntercomStatusDataAC = (
  ip_address: string,
  intercomStatusData: IntercomStatusDataType,
) => {
  return {
    type: intercomsReducerTypes.setIntercomStatusData,
    ip_address,
    intercomStatusData,
  } as const;
};

export type setIntercomsDataAT = ReturnType<typeof setIntercomsDataAC>;
export type setIntercomStatusDataAT = ReturnType<typeof setIntercomStatusDataAC>;
export type setSortIntercomsDataAT = ReturnType<typeof setSortIntercomsDataAC>;

export type actionType = setIntercomsDataAT | setIntercomStatusDataAT | setSortIntercomsDataAT;

type InitStateType = {
  intercomsDataList: DeviceDataType[];
  sortInfo: sortInfoType;
};

export const initState: InitStateType = {
  intercomsDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.FullName,
    directionSort: 1,
  },
};

export const intercomsReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case intercomsReducerTypes.setIntercomsDataList: {
    const copyState = { ...state };

    const listFavorites: DeviceDataType[] = [];
    const listNotFavorites: DeviceDataType[] = [];

    action.intercomsDataList.forEach((item) => {
      if (item.IsFavorite) {
        const newItem = state.intercomsDataList.find(
          (intercom) => intercom.id === item.id,
        );
        if (newItem) {
          newItem.IsFavorite = true;
          newItem.NicknameData = item.NicknameData;
          listFavorites.push(newItem);
        } else {
          listFavorites.push(item);
        }
      } else {
        const newItem = state.intercomsDataList.find(
          (intercom) => intercom.id === item.id,
        );
        if (newItem) {
          newItem.IsFavorite = false;
          newItem.NicknameData = item.NicknameData;
          listNotFavorites.push(newItem);
        } else {
          listNotFavorites.push(item);
        }
      }
    });

    const sortFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.intercomsDataList = [...sortFavorites, ...sortNotFavorites];

    return copyState;
  }
  case intercomsReducerTypes.setSortIntercomsData: {
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
        state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    const listFavorites: DeviceDataType[] = [];
    const listNotFavorites: DeviceDataType[] = [];

    state.intercomsDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareDevicesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.intercomsDataList = [...sortFavorites, ...sortNotFavorites];
    copyState.sortInfo.keySort = action.keySort;

    return copyState;
  }
  case intercomsReducerTypes.setIntercomStatusData: {
    const copyState = { ...state };

    copyState.intercomsDataList = state.intercomsDataList.map((intercom) => {
      if (intercom.attributes.ip_address === action.ip_address) {
        return {
          ...intercom,
          isCheckedStatus: true,
          attributes: {
            ...intercom.attributes,
            ...action.intercomStatusData,
          },
        };
      } else {
        return intercom;
      }
    });

    return copyState;
  }
  default: {
    return state;
  }
  }
};
