export type WSCountingAreaItemRes = {
    event: string,
    counting_area_id: string,
    count_people: {
        countPerson: number,
        countPersonIn: number,
        countPersonOut: number
    },
}

export type countingAreasStatusDescriptionsType = {
    [key in countingAreasStatusNamesEnum]: string;
};

export enum countingAreasActionNamesEnum {
    rules = 'Rules',
}

export enum countingAreasStatusNamesEnum {
    Rules = 'Rules',
}

export type Rules = {
    id: string,
    name: string,
    actions: string[],
    rule_details: string,
    is_running: boolean
}

type DoorAccessType = {
    Reader: string,
    Schedule: string
}

export type AccessLevel = {
    Key: string,
    CommonName: string,
    Status: string,
    DoorAccess: DoorAccessType[],
    StartsOn: string,
    EndsOn: string
}

export type CountingAreaRulesRes = Rules[];

export type CountingAreaItem = {
    id: string,
    name: string,
    isFavorite: boolean,
    countPerson: number,
    countPersonIn: number,
    countPersonOut: number,
    active_rule?: Rules,
    access_level?: AccessLevel,
    isShouldUpdateCount: boolean,
}

export type CountingAreaItemRes = CountingAreaItem[];

export type AccessLevelRes = {
    status: string
}

export type AccessLevelListRes = AccessLevel[];