import { sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { utils_instance } from '../utils/Utils';
import {
  InstanceGroupDetailResponseType,
  InstanceGroupHardwareReadersListResponse,
  InstanceGroupListType,
  ScheduleResponseInstanceGroups
} from '../types/instancesGroupsTypes';


enum instanceGroupsReducerTypes {
  setSortInstanceGroupData = 'instanceGroups/setSortInstanceGroupData',
  setInstanceGroupDataList = 'instanceGroups/setInstanceGroupDataList',
  setInstanceGroupData = 'instanceGroups/setInstanceGroupData',
  setInstanceGroupSchedules = 'instanceGroups/setInstanceGroupSchedules',
  setInstanceGroupReaders = 'instanceGroups/setInstanceGroupReaders'
}

export const setSortInstanceGroupDataAC = (
  keySort: sortKeyEnum
) => {
  //dispatch hardware floor groups additional data
  return {
    type: instanceGroupsReducerTypes.setSortInstanceGroupData,
    keySort
  } as const;
};

export const setInstanceGroupDataListAC = (
  instanceGroupDataList: InstanceGroupListType,
  totalCount: number
) => {
  return {
    type: instanceGroupsReducerTypes.setInstanceGroupDataList,
    instanceGroupDataList,
    totalCount,
  } as const;
};

export const setInstanceGroupDataAC = (
  InstanceGroupData: InstanceGroupDetailResponseType | null
) => {
  return {
    type: instanceGroupsReducerTypes.setInstanceGroupData,
    InstanceGroupData,
  } as const;
};

export const setInstanceGroupSchedulesAC
    = (instanceGroupSchedule: ScheduleResponseInstanceGroups) => {
      return {
        type: instanceGroupsReducerTypes.setInstanceGroupSchedules,
        instanceGroupSchedule
      } as const;
    };

export const setInstanceGroupReadersAC
    = (readersData: InstanceGroupHardwareReadersListResponse) => {
      return {
        type: instanceGroupsReducerTypes.setInstanceGroupReaders,
        readersData
      }as const;
    };


export type setSortInstanceGroupDataAT = ReturnType<typeof setSortInstanceGroupDataAC>;
export type setInstanceGroupDataListAT = ReturnType<typeof setInstanceGroupDataListAC>;
export type setInstanceGroupDataAT = ReturnType<typeof setInstanceGroupDataAC>;
export type setInstanceGroupSchedulesAT = ReturnType<typeof setInstanceGroupSchedulesAC>;
export type setInstanceGroupReadersAT = ReturnType<typeof setInstanceGroupReadersAC>;


export type actionType =
    | setSortInstanceGroupDataAT
    | setInstanceGroupDataListAT
    | setInstanceGroupDataAT
    | setInstanceGroupSchedulesAT
    | setInstanceGroupReadersAT;

type InitStateType = {
    InstanceGroupsDataList: InstanceGroupListType;
    sortInfo: sortInfoType;
    instanceGroupData: InstanceGroupDetailResponseType | null;
    schedules: ScheduleResponseInstanceGroups;
    readersData: InstanceGroupHardwareReadersListResponse | null;
};

export const initState: InitStateType = {
  InstanceGroupsDataList: {
    instance_groups: [],
    total_count: 0,
  },
  sortInfo:{
    keySort: sortKeyEnum.name,
    directionSort: 1,
  },
  instanceGroupData: null,
  schedules: {
    instances: []
  },
  readersData: null,
};

export const instanceGroupsReducer = (
  state: InitStateType = initState,
  action: actionType,
) => {
  switch (action.type) {
  case instanceGroupsReducerTypes.setSortInstanceGroupData: {
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
        state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    const listFavorites: InstanceGroupListType = { instance_groups: [], total_count: 0 };
    const listNotFavorites: InstanceGroupListType = { instance_groups: [], total_count: 0 };

    state.InstanceGroupsDataList.instance_groups.forEach((item) => {
      if (item.is_favourite) {
        listFavorites.instance_groups.push(item);
      } else {
        listNotFavorites.instance_groups.push(item);
      }
    });

    const sortFavorites = utils_instance.sortInstanceGroupsDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortInstanceGroupsDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.InstanceGroupsDataList = {
      instance_groups: [...sortFavorites, ...sortNotFavorites],
      total_count: copyState.InstanceGroupsDataList.total_count,
    };
    copyState.sortInfo.keySort = action.keySort;
    return copyState;
  }
  case instanceGroupsReducerTypes.setInstanceGroupDataList: {
    const copyState = { ...state };

    const listFavorites: InstanceGroupListType = { instance_groups: [], total_count: 0 };
    const listNotFavorites: InstanceGroupListType = { instance_groups: [], total_count: 0 };

    action.instanceGroupDataList.instance_groups.map((item) => {
      if (item.is_favourite) {
        listFavorites.instance_groups.push(item);
      } else {
        listNotFavorites.instance_groups.push(item);
      }
    });

    const sortFavorites = utils_instance.sortInstanceGroupsDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortInstanceGroupsDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.InstanceGroupsDataList.instance_groups = [...sortFavorites, ...sortNotFavorites];
    copyState.InstanceGroupsDataList.total_count = action.totalCount;
    return copyState;
  }
  case instanceGroupsReducerTypes.setInstanceGroupData: {
    const copyState = { ...state };
    copyState.instanceGroupData = action.InstanceGroupData;

    return copyState;
  }
  case instanceGroupsReducerTypes.setInstanceGroupSchedules: {
    const copyState = { ...state };

    const instances = action.instanceGroupSchedule.instances.map((item) => {
      const updatedItem = { ...item };

      updatedItem.schedules.active_schedule_list =
            utils_instance.parseDateList(item.schedules.active_schedule_list);
      updatedItem.schedules.hold_schedule_list =
            utils_instance.parseDateList(item.schedules.hold_schedule_list);
      updatedItem.schedules.currently_schedule_list =
            utils_instance.parseDateList(item.schedules.currently_schedule_list);

      return updatedItem;

    });

    return { ...copyState, schedules: { ...copyState.schedules, instances } };
  }
  case instanceGroupsReducerTypes.setInstanceGroupReaders: {
    const copyState = { ...state };
    copyState.readersData = action.readersData;
    return copyState;
  }
  default: {
    return state;
  }
  }
};