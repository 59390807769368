export enum sortKeyEnum {
  CommonName = 'CommonName',
  Status = 'Status',
  ControllerCommonName = 'ControllerCommonName',

  InstanceName = 'InstanceName',

  FullName = 'FullName',
  IpAddress = 'IpAddress',
  MacAddress = 'MacAddress',
  Location = 'Location',

  UserEmail = 'user_email',
  UserAction = 'user_action',
  Device = 'device',
  InitDate = 'init_date',

  InstanceDetailName = 'instance_name',
  ServerName = 'server_name',
  Territory = 'territory',
  Region = 'region',
  Country = 'country',
  City = 'city',
  BuildingAddress = 'building_address',
  SeccCode = 'secc_code',

  name = 'name',
  systemisowner = 'systemisowner',

  nickname = 'nickname',
  people = 'people',
  in = 'in',
  out = 'out',
  Alerts = 'Alerts',
  AccessLevel = 'access_level',
  AccessLevelStatus = 'access_level_status',

  StartDate = 'start_date',
  EndDate = 'end_date',
  HolidayGroups = 'holiday_groups',
}

export enum subHeaderTableType {
  readers = 'readers',
  none = 'none',
  instanceGroupAction = 'instanceGroupAction'
}

export type headerTableType = {
  id: number;
  name: string;
  value?: sortKeyEnum;
  isSortable?: boolean;
  isMultiselect?: boolean;
  type?: subHeaderTableType;
};
