import React, { useEffect, useState } from 'react';
import s from './SideNav.module.css';
import icon from '../../../assets/icons/Icon.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { navigationGroupType, navigationItem } from '../../../types/types';
import { useIsNarrower } from '../../../utils/useIsNarrower';
import SelectInstance from '../SelectInstance/SelectInstance';
import { useDispatch } from 'react-redux';
import { setCurrentPathAC } from '../../../store/appReducer';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';
import { useUserInfo } from '../../../store/selectors/useUserInfo';

type propsType = {
  header: string;
  groups: navigationGroupType[];
  url: string;
};

export const SideNav: React.FC<propsType> = (props) => {
  const { header, groups, url } = props;

  const dispatch = useDispatch();

  const location = useLocation();
  const navigate = useNavigate();
  const isMobile = useIsNarrower(920);

  const currentInstanceData = useCurrentInstanceData();
  const userInfo = useUserInfo();

  const isAvailableSelectInstance =
    userInfo?.user_permissions.instances_dc_receive_permission ||
    userInfo?.user_permissions.instances_no_dc_receive_permission;

  const [isOpenSideNav, setIsOpenSideNav] = useState<boolean>(false);
  const [showModalInstance, setShowModalInstance] = useState<boolean>(false);

  const changePath = (item: navigationItem) => {
    navigate(`/${item.path}/${url}`);
  };

  const handlePath = (item: navigationItem) => {
    dispatch(setCurrentPathAC(item.path));
    if (currentInstanceData) {
      changePath(item);
    } else {
      if (item.isInstanceRequired) {
        isAvailableSelectInstance && setShowModalInstance(true);
      } else {
        changePath(item);
      }
    }
  };

  const ChangeInstanceClick = () => {
    if (location.pathname === '/error') return;
    isAvailableSelectInstance && setShowModalInstance(true);
  };

  const groupLabel = groups.map((group) => {
    const groupItem = group.items
      .filter((item) => !(!currentInstanceData && item.isInstanceRequired))
      .map((item, index) => (
        <div
          onClick={() => handlePath(item)}
          key={index}
          className={`
            text-body text-xs 
            ${s.NavBarGroupItem} 
            ${location.pathname === `/${item.path}/${url}` && s.NavBarGroupItem__selected}
            ${location.pathname === `/${item.path}` && s.NavBarGroupItem__selected}
          `}
        >
          {item.content}
        </div>
      ));

    if (groupItem.length === 0) return null;

    return (
      <div key={group.label}>
        <div className={s.navbarHeader}>
          <img src={group.icon} alt={group.label} />
          <span className={`${s.NavBarGroupTitle} text-body text-sm`}>{group.label}</span>
        </div>
        <div className={s.NavBarGroupBody}>{groupItem}</div>
      </div>
    );
  });

  useEffect(() => {
    setIsOpenSideNav(!isMobile);
  }, [isMobile]);

  return (
    <div className={`${s.navbarWrapper} ${isMobile && s.navbarWrapper_mobile}`}>
      {isMobile && (
        <div className={s.hamMenu} onClick={() => setIsOpenSideNav(!isOpenSideNav)}>
          <span
            className={`${s.hamLine} ${s.hamLine1} ${isOpenSideNav && s.hamLine1Active}`}
          ></span>
          <span className={`${s.hamLine} ${isOpenSideNav && s.hamLine2Active}`}></span>
          <span
            className={`${s.hamLine} ${s.hamLine3} ${isOpenSideNav && s.hamLine3Active}`}
          ></span>
        </div>
      )}

      <div
        className={`${s.navbarBody} ${isMobile && s.navbarWrapper} ${
          isOpenSideNav && s.navbarBody_active
        }`}
      >
        <div className={s.navbarContainer}>
          <div
            className={`${s.navbarHeader} ${s.navbarTopHeader} ${
              isAvailableSelectInstance && s.navbarTopHeader__onClick
            } ${isMobile && s.navbarTopHeader_mobile}`}
            onClick={ChangeInstanceClick}
          >
            <img src={icon} alt="icon" />
            <span className={s.navbarHeaderText}>{header}</span>
          </div>
          <div>{groupLabel}</div>
        </div>
      </div>

      {isMobile && isOpenSideNav && (
        <div className={s.navbarBackground} onClick={() => setIsOpenSideNav(!isOpenSideNav)}>
          {}
        </div>
      )}

      {showModalInstance && <SelectInstance setShowModalInstance={setShowModalInstance} />}
    </div>
  );
};
