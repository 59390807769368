export enum intercomStatusNamesEnum {
  Online = 'Online',
  Offline = 'Offline',
  Unknown = 'Unknown',
}

export type intercomStatusDescriptionsType = {
  [key in intercomStatusNamesEnum]: string;
};

export type IntercomStatusDataType = {
  devType?: string,
  variant: string
  variantId: string
  customerId: string
  serialNumber: string
  macAddr: string
  hwVersion: string
  swVersion: string
  buildType: string
  firmwarePackage: string
  deviceName: string
}
