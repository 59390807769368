import { sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { UserLogDataType } from '../types/userLogTypes';
import { SysLogItemType } from '../types/sysLogTypes';

enum userLogsReducerTypes {
  setSysLogsData = 'sysLogs/setSysLogsData',
  setSortSysLogsData = 'sysLogs/setSortSysLogsData',
}

export const setSysLogsDataAC = (sysLogsData: Array<SysLogItemType>, totalCount: number) => {
  return {
    type: userLogsReducerTypes.setSysLogsData,
    sysLogsData,
    totalCount,
  } as const;
};

export const setSortSysLogsDataAC = (keySort: sortKeyEnum) => {
  return {
    type: userLogsReducerTypes.setSortSysLogsData,
    keySort,
  } as const;
};

export type setSysLogsDataAT = ReturnType<typeof setSysLogsDataAC>;
export type setSortSysLogsDataAT = ReturnType<typeof setSortSysLogsDataAC>;

export type actionType = setSysLogsDataAT | setSortSysLogsDataAT;

type InitStateType = {
  sysLogsDataList: SysLogItemType[];
  sortInfo: sortInfoType;
  totalCount: number;
};

export const initState: InitStateType = {
  sysLogsDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.InitDate,
    directionSort: -1,
  },
  totalCount: 0,
};

export const sysLogsReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case userLogsReducerTypes.setSysLogsData: {
    const copyState = { ...state };

    let newKeySort = copyState.sortInfo.keySort as keyof UserLogDataType;
    newKeySort = newKeySort ? newKeySort : (sortKeyEnum.InitDate as keyof UserLogDataType);

    copyState.sysLogsDataList = action.sysLogsData;

    copyState.totalCount = action.totalCount;

    return copyState;
  }

  case userLogsReducerTypes.setSortSysLogsData: {
    const copyState = { ...state };

    copyState.sortInfo = {
      directionSort:
          state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1,
      keySort: action.keySort,
    };

    return copyState;
  }

  default: {
    return state;
  }
  }
};
