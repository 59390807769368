import { sortKeyEnum } from '../types/tableTemplateTypes';
import { AccessLevel, CountingAreaItem, Rules } from '../types/countingAreasTypes';
import { sortInfoType } from '../types/readersTypes';
import { utils_instance } from '../utils/Utils';

enum countingAreasReducerTypes {
    setCountingAreasDataList = 'counting_areas/setCountingAreasDataList',
    setCountingAreasCountData = 'counting_areas/setCountingAreasCountData',
    setCountingAreasRecalculate = 'counting_areas/setCountingAreasRecalculate',
    setSortCountingAreasData = 'counting_areas/setSortCountingAreasData',
    setCountingAreaRules = 'counting_areas/setCountingAreaRules',
    updateCountingAreaData = 'counting_areas/updateCountingAreaData',
    updateCountingAreasAccessLevelStatusData =
        'counting_areas/updateCountingAreasAccessLevelStatusData',
    updateCountingAreasAccessLevelData =
        'counting_areas/updateCountingAreasAccessLevelData',
    updateCountingAreasActiveRuleData =
        'counting_areas/updateCountingAreasActiveRuleData',
    setCountingAreasShouldUpdate =
        'counting_areas/setCountingAreasShouldUpdate',
}

export const setCountingAreasDataListAC = (
  countingAreasDataList: CountingAreaItem[],
) => {
  return {
    type: countingAreasReducerTypes.setCountingAreasDataList,
    countingAreasDataList,
  } as const;
};

export const setCountingAreasCountDataAC = (
  countingArea: CountingAreaItem,
) => {
  return {
    type: countingAreasReducerTypes.setCountingAreasCountData,
    countingArea,
  } as const;
};

export const setCountingAreaRecalculateAC = (
  id: string
) => {
  return {
    type: countingAreasReducerTypes.setCountingAreasRecalculate,
    id,
  } as const;
};

export const setSortCountingAreasDataAC = (keySort: sortKeyEnum) => {
  return {
    type: countingAreasReducerTypes.setSortCountingAreasData,
    keySort,
  } as const;
};

export const setCountingAreaRulesAC = (countingAreaRules: Rules[]) => {
  return {
    type: countingAreasReducerTypes.setCountingAreaRules,
    countingAreaRules,
  } as const;
};

export const updateCountingAreaDataAC = (
  id: string,
  countingArea: CountingAreaItem,
) => {
  return {
    type: countingAreasReducerTypes.updateCountingAreaData,
    countingArea,
    id,
  } as const;
};

export const updateCountingAreasAccessLevelStatusDataAC = (
  accessLevelKey: string,
  status: string,
) => {
  return {
    type: countingAreasReducerTypes.updateCountingAreasAccessLevelStatusData,
    accessLevelKey,
    status,
  } as const;
};

export const updateCountingAreasAccessLevelDataAC = (
  accessLevel: AccessLevel | undefined,
  countingAreaId: string,
) => {
  return {
    type: countingAreasReducerTypes.updateCountingAreasAccessLevelData,
    accessLevel,
    countingAreaId,
  } as const;
};

export const updateCountingAreasActiveRuleDataAC = (
  activeRule: Rules | undefined,
  countingAreaId: string,
) => {
  return {
    type: countingAreasReducerTypes.updateCountingAreasActiveRuleData,
    activeRule,
    countingAreaId,
  } as const;
};
export const setCountingAreasShouldUpdateAC = (
  countingAreasShouldUpdate: boolean,
) => {
  return {
    type: countingAreasReducerTypes.setCountingAreasShouldUpdate,
    countingAreasShouldUpdate,
  } as const;
};

export type setCountingAreasDataListAT = ReturnType<typeof setCountingAreasDataListAC>;
export type setSortCountingAreasDataAT = ReturnType<typeof setSortCountingAreasDataAC>;
export type addCountingAreasCountDataAT = ReturnType<typeof setCountingAreasCountDataAC>;
export type setCountingAreaRecalculateAT = ReturnType<typeof setCountingAreaRecalculateAC>;
export type setCountingAreaRulesAT = ReturnType<typeof setCountingAreaRulesAC>;
export type updateCountingAreaDataAT = ReturnType<typeof updateCountingAreaDataAC>;
export type updateCountingAreasAccessLevelStatusDataAT =
    ReturnType<typeof updateCountingAreasAccessLevelStatusDataAC>;
export type updateCountingAreasAccessLevelDataAT =
    ReturnType<typeof updateCountingAreasAccessLevelDataAC>;
export type updateCountingAreasActiveRuleDataAT =
    ReturnType<typeof updateCountingAreasActiveRuleDataAC>;
export type setCountingAreasShouldUpdateAT =
    ReturnType<typeof setCountingAreasShouldUpdateAC>;

export type actionType = setCountingAreasDataListAT
  | setSortCountingAreasDataAT
  | addCountingAreasCountDataAT
  | setCountingAreaRecalculateAT
  | setCountingAreaRulesAT
  | updateCountingAreaDataAT
  | updateCountingAreasAccessLevelStatusDataAT
  | updateCountingAreasAccessLevelDataAT
  | updateCountingAreasActiveRuleDataAT
  | setCountingAreasShouldUpdateAT;


type InitStateType = {
  countingAreas: CountingAreaItem[],
  countingAreasShouldUpdate: boolean,
  currentRules: Rules[],
  sortInfo: sortInfoType
};

export const initState: InitStateType = {
  countingAreasShouldUpdate: true,
  countingAreas: [],
  currentRules: [],
  sortInfo: {
    keySort: sortKeyEnum.name,
    directionSort: 1,
  },
};

export const countingAreasReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case countingAreasReducerTypes.setCountingAreasDataList: {
    const copyState = { ...state };
    const listFavorites: CountingAreaItem[] = [];
    const listNotFavorites: CountingAreaItem[] = [];

    action.countingAreasDataList.forEach((item) => {
      const newItem = state.countingAreas.find(countingArea => countingArea.id === item.id);
      if (item.isFavorite) {
        if (newItem) {
          newItem.isFavorite = true;
          newItem.isShouldUpdateCount = true;
          listFavorites.push(newItem);
        } else {
          listFavorites.push({ ...item, isShouldUpdateCount: true });
        }
      } else {
        if (newItem) {
          newItem.isFavorite = false;
          newItem.isShouldUpdateCount = true;
          listNotFavorites.push(newItem);
        } else {
          listNotFavorites.push({ ...item, isShouldUpdateCount: true });
        }
      }
    });

    const sortFavorites = utils_instance.sortCountingAreasDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );

    const sortNotFavorites = utils_instance.sortCountingAreasDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.countingAreas = [...sortFavorites, ...sortNotFavorites];
    return copyState;
  }
  case countingAreasReducerTypes.setCountingAreasCountData: {
    const copyState = { ...state };
    copyState.countingAreas = copyState.countingAreas.map(item => {
      if(item.id === action.countingArea.id) {
        const {
          countPerson,
          countPersonIn,
          countPersonOut,
          isShouldUpdateCount
        } = action.countingArea;
        return {
          ...item,
          countPerson,
          countPersonIn,
          countPersonOut,
          isShouldUpdateCount,
        };
      }
      return item;
    });
    return copyState;
  }
  case countingAreasReducerTypes.setCountingAreasRecalculate: {
    const copyState = { ...state };
    copyState.countingAreas = copyState.countingAreas.map(item => {
      if(item.id === action.id) {
        return { ...item, isShouldUpdateCount: true };
      }
      return item;
    });
    return copyState;
  }
  case countingAreasReducerTypes.setSortCountingAreasData: {
    const copyState = { ...state };
    const { keySort, directionSort } = state.sortInfo;
    const listFavorites: CountingAreaItem[] = [];
    const listNotFavorites: CountingAreaItem[] = [];

    copyState.sortInfo.directionSort = keySort === action.keySort ? directionSort * -1 : 1;

    copyState.countingAreas.forEach((item) => {
      if (item.isFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortCountingAreasDataList(
      action.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );

    const sortNotFavorites = utils_instance.sortCountingAreasDataList(
      action.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.countingAreas = [...sortFavorites, ...sortNotFavorites];
    copyState.sortInfo.keySort = action.keySort;
    return copyState;
  }
  case countingAreasReducerTypes.setCountingAreaRules: {
    const copyState = { ...state };
    copyState.currentRules = action.countingAreaRules;
    return copyState;
  }
  case countingAreasReducerTypes.updateCountingAreaData: {
    const copyState = { ...state };
    const idx = copyState.countingAreas.findIndex(item => item.id === action.id);
    if(idx >= 0) copyState.countingAreas[idx] = action.countingArea;
    copyState.countingAreas = [...copyState.countingAreas];
    return copyState;
  }
  case countingAreasReducerTypes.updateCountingAreasAccessLevelStatusData: {
    const copyState = { ...state };
    copyState.countingAreas = copyState.countingAreas.map(item => {
      const newObj = { ...item };
      if(newObj.access_level?.Key === action.accessLevelKey && newObj.access_level){
        newObj.access_level.Status = action.status;
      }
      return newObj;
    });
    return copyState;
  }
  case countingAreasReducerTypes.updateCountingAreasAccessLevelData: {
    const copyState = { ...state };
    const { accessLevel, countingAreaId } = action;
    const idx = copyState.countingAreas.findIndex(item => item.id === countingAreaId);
    if(idx >= 0){
      copyState.countingAreas[idx].access_level = accessLevel;
      copyState.countingAreas = [...copyState.countingAreas];
    }
    return copyState;
  }
  case countingAreasReducerTypes.updateCountingAreasActiveRuleData: {
    const copyState = { ...state };
    const { activeRule, countingAreaId } = action;
    const idx = copyState.countingAreas.findIndex(item => item.id === countingAreaId);
    if(idx >= 0){
      copyState.countingAreas[idx].active_rule = activeRule;
      copyState.countingAreas = [...copyState.countingAreas];
    }
    return copyState;
  }
  case countingAreasReducerTypes.setCountingAreasShouldUpdate: {
    const copyState = { ...state };
    copyState.countingAreasShouldUpdate = action.countingAreasShouldUpdate;
    return copyState;
  }
  default: {
    return state;
  }
  }
};
