import { sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { utils_instance } from '../utils/Utils';
import {
  FloorGroupCommonDataType,
  FloorGroupMainDataType,
  FloorGroupAdditionalDataResponseType,
  FloorGroupFullDataType,
} from '../types/elevatorsTypes';

enum elevatorsReducerTypes {
  setFloorGroupAdditionalData = 'elevators/setFloorGroupAdditionalData',
  setFloorGroupsDataList = 'elevators/setFloorGroupsDataList',
  sortFloorGroupsDataList = 'elevators/sortFloorGroupsDataList',
  setFloorGroupFullData = 'elevators/setFloorGroupFullData',
}

export const setFloorGroupAdditionalDataAC = (
  floorGroupAdditionalData: FloorGroupAdditionalDataResponseType | null,
) => {
  //dispatch hardware floor groups additional data
  return {
    type: elevatorsReducerTypes.setFloorGroupAdditionalData,
    floorGroupAdditionalData,
  } as const;
};

export const setFloorGroupsDataListAC = (floorGroupsDataList: FloorGroupMainDataType[]) => {
  //dispatch main hardware floor groups data list
  return {
    type: elevatorsReducerTypes.setFloorGroupsDataList,
    floorGroupsDataList,
  } as const;
};

export const sortFloorGroupsDataAC = (keySort: sortKeyEnum) => {
  //dispatch sort FloorGroups data
  return {
    type: elevatorsReducerTypes.sortFloorGroupsDataList,
    keySort,
  } as const;
};

export const setFloorGroupFullDataAC = (floorGroupFullData: FloorGroupFullDataType | null) => {
  //dispatch set Floor Group Full Data
  return {
    type: elevatorsReducerTypes.setFloorGroupFullData,
    floorGroupFullData,
  } as const;
};

export type setFloorGroupAdditionalDataAT = ReturnType<typeof setFloorGroupAdditionalDataAC>;
export type setFloorGroupsDataListAT = ReturnType<typeof setFloorGroupsDataListAC>;
export type sortFloorGroupsDataAT = ReturnType<typeof sortFloorGroupsDataAC>;
export type setFloorGroupFullDataAT = ReturnType<typeof setFloorGroupFullDataAC>;

export type actionType =
  | setFloorGroupAdditionalDataAT
  | setFloorGroupsDataListAT
  | sortFloorGroupsDataAT
  | setFloorGroupFullDataAT;

type InitStateType = {
  floorGroupsDataList: FloorGroupMainDataType[];
  timespecDataList: FloorGroupCommonDataType[];
  threatLevelDataList: FloorGroupMainDataType[];
  elevatorDataList: FloorGroupCommonDataType[];
  sortInfo: sortInfoType;
  floorGroupFullData: FloorGroupFullDataType | null;
};

export const initState: InitStateType = {
  floorGroupsDataList: [],
  timespecDataList: [],
  threatLevelDataList: [],
  elevatorDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.name,
    directionSort: 1,
  },
  floorGroupFullData: null,
};

export const elevatorsReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case elevatorsReducerTypes.setFloorGroupAdditionalData: {
    const copyState = { ...state };

    if (action.floorGroupAdditionalData) {
      copyState.timespecDataList = action.floorGroupAdditionalData.timespec_data;
      copyState.threatLevelDataList = action.floorGroupAdditionalData.threat_level_data;
      copyState.elevatorDataList = action.floorGroupAdditionalData.elevator_data;
    } else {
      copyState.timespecDataList = [];
      copyState.threatLevelDataList = [];
      copyState.elevatorDataList = [];
    }

    return copyState;
  }

  case elevatorsReducerTypes.setFloorGroupsDataList: {
    const copyState = { ...state };

    copyState.floorGroupsDataList = utils_instance.sortHardwareFloorGroupsDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      action.floorGroupsDataList,
    );

    return copyState;
  }

  case elevatorsReducerTypes.sortFloorGroupsDataList: {
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
      state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    const listFavorites: FloorGroupMainDataType[] = [];
    const listNotFavorites: FloorGroupMainDataType[] = [];

    state.floorGroupsDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortElevatorsDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortElevatorsDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.floorGroupsDataList = [...sortFavorites, ...sortNotFavorites];
    copyState.sortInfo.keySort = action.keySort;

    return copyState;
  }

  case elevatorsReducerTypes.setFloorGroupFullData: {
    const copyState = { ...state };

    copyState.floorGroupFullData = action.floorGroupFullData;

    return copyState;
  }

  default: {
    return state;
  }
  }
};
