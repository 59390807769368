import { HardwareControllerData, sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { utils_instance } from '../utils/Utils';

enum controllersReducerTypes {
  setHardwareControllersDataList = 'controllers/setHardwareControllersDataList',
  setSortControllersData = 'controllers/setSortControllersData',
}

export const setHardwareControllersDataListAC = (
  hardwareControllersDataList: HardwareControllerData[],
) => {
  //dispatch main hardware controllers data list
  return {
    type: controllersReducerTypes.setHardwareControllersDataList,
    hardwareControllersDataList,
  } as const;
};

export const setSortControllersDataAC = (keySort: sortKeyEnum) => {
  //dispatch sort controllers data
  return {
    type: controllersReducerTypes.setSortControllersData,
    keySort,
  } as const;
};

export type setHardwareControllersDataListAT = ReturnType<typeof setHardwareControllersDataListAC>;
export type setSortControllersDataAT = ReturnType<typeof setSortControllersDataAC>;

export type actionType = setHardwareControllersDataListAT | setSortControllersDataAT;

type InitStateType = {
  hardwareControllersDataList: HardwareControllerData[];
  sortInfo: sortInfoType;
};

export const initState: InitStateType = {
  hardwareControllersDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.CommonName,
    directionSort: 1,
  },
};

export const controllersReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case controllersReducerTypes.setHardwareControllersDataList: {
    const copyState = { ...state };

    const listFavorites: HardwareControllerData[] = [];
    const listNotFavorites: HardwareControllerData[] = [];

    action.hardwareControllersDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareControllersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareControllersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.hardwareControllersDataList = [...sortFavorites, ...sortNotFavorites];

    return copyState;
  }

  case controllersReducerTypes.setSortControllersData: {
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
        state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    const listFavorites: HardwareControllerData[] = [];
    const listNotFavorites: HardwareControllerData[] = [];

    state.hardwareControllersDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareControllersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareControllersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.hardwareControllersDataList = [...sortFavorites, ...sortNotFavorites];
    copyState.sortInfo.keySort = action.keySort;

    return copyState;
  }

  default: {
    return state;
  }
  }
};
