import {
  errorType,
  InstancePublicDataType,
  notificationEnum,
  notificationType,
  selectInstanceModeEnum,
} from '../types/appTypes';
import config from '../config';
import { LoadDataErrors, PathNames } from '../types/types';

enum appReducerTypes {
  setCurrentIp = 'app/setCurrentIp',
  setCurrentInstanceData = 'app/setCurrentInstanceData',
  addNotification = 'app/addNotification',
  setAllInstancesList = 'app/setAllInstancesList',
  resetState = 'app/resetState',
  setCurrentPath = 'app/setCurrentPath',
  setSelectInstanceMode = 'app/setSelectInstanceMode',
  removeNotification = 'app/removeNotification',
  setSocketObject = 'app/setSocketObject',
  clearSocketObject = 'app/clearSocketObject',
  blockSocketObject = 'app/blockSocketObject',
  setErrorStatus = 'app/setErrorStatus',
  setIsSocketConnected = 'app/setIsSocketConnected',
}

export const setCurrentIpAC = (currentIp: string, isIPv6: boolean = false) => {
  //dispatch current ip
  return {
    type: appReducerTypes.setCurrentIp,
    currentIp,
    isIPv6,
  } as const;
};

export const setCurrentInstanceDataAC = (
  currentInstance: InstancePublicDataType | null
) => {
  //dispatch all data about current instance
  return {
    type: appReducerTypes.setCurrentInstanceData,
    currentInstance,
  } as const;
};

export const addNotificationAC = (typeNotification: notificationEnum, description: string) => {
  //dispatch notification in common list
  return {
    type: appReducerTypes.addNotification,
    typeNotification,
    description,
  } as const;
};

export const setAllInstancesListAC = (instancesList: InstancePublicDataType[]) => {
  //dispatch all instances list
  return {
    type: appReducerTypes.setAllInstancesList,
    instancesList,
  } as const;
};

export const resetAppStateAC = () => {
  //dispatch init data to State
  return {
    type: appReducerTypes.resetState,
  } as const;
};

export const setCurrentPathAC = (currentPath: PathNames) => {
  //dispatch current path from url path
  return {
    type: appReducerTypes.setCurrentPath,
    currentPath,
  } as const;
};

export const setSelectInstanceModeAC = (selectInstanceMode: selectInstanceModeType) => {
  //dispatch select instance mode
  return {
    type: appReducerTypes.setSelectInstanceMode,
    selectInstanceMode,
  } as const;
};

export const removeNotificationAC = (created_at: number) => {
  //dispatch remove notification from common list
  return {
    type: appReducerTypes.removeNotification,
    created_at,
  } as const;
};

export const setSocketObjectAC = (token: string) => {
  //dispatch set socket object
  return {
    type: appReducerTypes.setSocketObject,
    token,
  } as const;
};

export const clearSocketObjectAC = () => {
  //dispatch clear socket object
  return {
    type: appReducerTypes.clearSocketObject,
  } as const;
};

export const blockSocketObjectAC = () => {
  //dispatch block socket object
  return {
    type: appReducerTypes.blockSocketObject,
  } as const;
};

export const setErrorStatusAC = (error: errorType) => {
  //dispatch error status
  return {
    type: appReducerTypes.setErrorStatus,
    error,
  } as const;
};

export const setIsSocketConnectedAC = (isSocketConnected: boolean) => {
  //dispatch isSocketConnected value
  return {
    type: appReducerTypes.setIsSocketConnected,
    isSocketConnected,
  } as const;
};

export type setCurrentIpAT = ReturnType<typeof setCurrentIpAC>;
export type setCurrentInstanceDataAT = ReturnType<typeof setCurrentInstanceDataAC>;
export type addNotificationAT = ReturnType<typeof addNotificationAC>;
export type setAllInstancesListAT = ReturnType<typeof setAllInstancesListAC>;
export type resetStateAT = ReturnType<typeof resetAppStateAC>;
export type setCurrentPathAT = ReturnType<typeof setCurrentPathAC>;
export type setSelectInstanceModeAT = ReturnType<typeof setSelectInstanceModeAC>;
export type removeNotificationAT = ReturnType<typeof removeNotificationAC>;
export type setSocketObjectAT = ReturnType<typeof setSocketObjectAC>;
export type clearSocketObjectAT = ReturnType<typeof clearSocketObjectAC>;
export type blockSocketObjectAT = ReturnType<typeof blockSocketObjectAC>;
export type setErrorStatusAT = ReturnType<typeof setErrorStatusAC>;
export type setIsSocketConnectedAT = ReturnType<typeof setIsSocketConnectedAC>;

export type actionType =
  | setCurrentIpAT
  | setCurrentInstanceDataAT
  | addNotificationAT
  | setAllInstancesListAT
  | resetStateAT
  | setCurrentPathAT
  | setSelectInstanceModeAT
  | removeNotificationAT
  | setSocketObjectAT
  | clearSocketObjectAT
  | blockSocketObjectAT
  | setErrorStatusAT
  | setIsSocketConnectedAT;

// TODO move from this
export type selectInstanceModeType = {
  selectInstanceMode: selectInstanceModeEnum;
  key: string;
  value: string | number | string[];
  label: string;
};

type InitStateType = {
  currentIp: string;
  isIPv6: boolean;
  currentInstanceData: InstancePublicDataType | null;

  notificationsList: notificationType[];

  instancesList: InstancePublicDataType[];

  selectInstanceMode: selectInstanceModeType;

  currentPath: PathNames;

  socket?: WebSocket;
  isSocketAllowed: boolean;
  isSocketConnected: boolean;

  error: errorType;
};

export const initState: InitStateType = {
  currentIp: '',
  isIPv6: false,
  currentInstanceData: null,

  notificationsList: [],

  instancesList: [],

  selectInstanceMode: {
    selectInstanceMode: selectInstanceModeEnum.regionMode,
    key: 'region',
    value: '',
    label: 'Region',
  },

  currentPath: PathNames.readers,

  socket: undefined,
  isSocketAllowed: true,
  isSocketConnected: false,

  error: {
    messageError: null,
    typeError: LoadDataErrors.notError,
  },
};

export const appReducer = (state: InitStateType = initState, action: actionType): InitStateType => {
  switch (action.type) {
  case appReducerTypes.setCurrentIp: {
    const copyState = { ...state };

    copyState.currentIp = action.currentIp;
    copyState.isIPv6 = action.isIPv6;

    return copyState;
  }
  case appReducerTypes.setCurrentInstanceData: {
    const copyState = { ...state };

    copyState.currentInstanceData = action.currentInstance;

    return copyState;
  }
  case appReducerTypes.addNotification: {
    const copyState = { ...state };
    const newNotification: notificationType = {
      type: action.typeNotification,
      description: action.description,
      created_at: Date.now(),
    };
    copyState.notificationsList = [newNotification, ...copyState.notificationsList];
    return copyState;
  }
  case appReducerTypes.setAllInstancesList: {
    const copyState = { ...state };

    copyState.instancesList = action.instancesList;

    return copyState;
  }
  case appReducerTypes.resetState: {
    return initState;
  }
  case appReducerTypes.setCurrentPath: {
    const copyState = { ...state };

    copyState.currentPath = action.currentPath;

    return copyState;
  }
  case appReducerTypes.setSelectInstanceMode: {
    const copyState = { ...state };

    copyState.selectInstanceMode = action.selectInstanceMode;

    return copyState;
  }
  case appReducerTypes.removeNotification: {
    const copyState = { ...state };
    copyState.notificationsList = state.notificationsList.filter(
      (item) => item.created_at !== action.created_at,
    );
    return copyState;
  }
  case appReducerTypes.setSocketObject: {
    const copyState = { ...state };

    const url = `${config.apiUrlWs}/ws?token=${action.token}`;

    copyState.socket = new WebSocket(url);

    return copyState;
  }
  case appReducerTypes.clearSocketObject: {
    const copyState = { ...state };

    copyState.socket = undefined;

    return copyState;
  }
  case appReducerTypes.blockSocketObject: {
    const copyState = { ...state };

    copyState.socket = undefined;
    copyState.isSocketAllowed = false;
    copyState.isSocketConnected = false;

    return copyState;
  }
  case appReducerTypes.setErrorStatus: {
    const copyState = { ...state };

    copyState.error = action.error;

    return copyState;
  }
  case appReducerTypes.setIsSocketConnected: {
    const copyState = { ...state };

    copyState.isSocketConnected = action.isSocketConnected;

    return copyState;
  }
  default: {
    return state;
  }
  }
};
