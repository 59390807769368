import { sortInfoType } from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { UserLogDataType } from '../types/userLogTypes';

enum userLogsReducerTypes {
  setUserLogsData = 'userLogs/setUserLogsData',
  setSortUserLogsData = 'userLogs/setSortUserLogsData',
}

export const setUserLogsDataAC = (userLogsData: Array<UserLogDataType>, totalCount: number) => {
  return {
    type: userLogsReducerTypes.setUserLogsData,
    userLogsData,
    totalCount,
  } as const;
};

export const setSortUserLogsDataAC = (keySort: sortKeyEnum) => {
  return {
    type: userLogsReducerTypes.setSortUserLogsData,
    keySort,
  } as const;
};

export type setUserLogsDataAT = ReturnType<typeof setUserLogsDataAC>;
export type setSortUserLogsDataAT = ReturnType<typeof setSortUserLogsDataAC>;

export type actionType = setUserLogsDataAT | setSortUserLogsDataAT;

type InitStateType = {
  userLogsDataList: UserLogDataType[];
  sortInfo: sortInfoType;
  totalCount: number;
};

export const initState: InitStateType = {
  userLogsDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.InitDate,
    directionSort: -1,
  },
  totalCount: 0,
};

export const userLogsReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case userLogsReducerTypes.setUserLogsData: {
    const copyState = { ...state };

    let newKeySort = copyState.sortInfo.keySort as keyof UserLogDataType;
    newKeySort = newKeySort ? newKeySort : (sortKeyEnum.InitDate as keyof UserLogDataType);

    copyState.userLogsDataList = action.userLogsData.sort((a, b) => {
      if ((a[newKeySort] || '') > (b[newKeySort] || '')) {
        return copyState.sortInfo.directionSort;
      } else {
        return copyState.sortInfo.directionSort * -1;
      }
    });

    copyState.totalCount = action.totalCount;

    return copyState;
  }

  case userLogsReducerTypes.setSortUserLogsData: {
    const copyState = { ...state };

    copyState.sortInfo = {
      directionSort:
          state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1,
      keySort: action.keySort,
    };

    return copyState;
  }

  default: {
    return state;
  }
  }
};
