import React, { ReactNode } from 'react';
import cs from '../../../styles/commonStyles.module.css';
import s from './CustomModal.module.css';
import { Button } from '@wework/ray2';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { CircularProgress } from '@mui/material';
import ReactDOM from 'react-dom';

type propsType = {
  handleClose?: (value: boolean) => void;
  textCloseButton?: string;
  handleConfirm?: () => void;
  textSendButton?: string;
  titleText?: string;
  children?: ReactNode;
  width?: number;
  isLoading?: boolean;
  isAvailableConfirm?: boolean;
  customButtons?: React.ReactElement;
  isLineHovered?: boolean;
  isImmediatelyClosed?: boolean;
};

const CustomModal: React.FC<propsType> = (props) => {
  const {
    handleClose,
    textCloseButton,
    handleConfirm,
    textSendButton,
    titleText,
    children,
    width,
    isLoading,
    isAvailableConfirm,
    customButtons,
    isLineHovered,
    isImmediatelyClosed
  } = props;

  const closeModal = () => {
    handleClose && handleClose(false);
  };

  const confirmModal = () => {
    isImmediatelyClosed && handleClose && handleClose(false);
    handleConfirm && !isAvailableConfirm && handleConfirm();
  };

  if (isLoading) {
    return ReactDOM.createPortal(
      <>
        <div className={s.modalBackground} onClick={closeModal} />
        <div className={s.modalContainer} style={{ width: `${width}px` }}>
          <div className={cs.position__center}>
            <CircularProgress />
          </div>
        </div>
      </>,
      document.body,
    );
  }

  return ReactDOM.createPortal(
    <>
      <div className={s.modalBackground} onClick={closeModal} />

      <div
        className={`${s.modalContainer} ${isLineHovered ? s.modalContainerMoreInfo : ''}`}
        style={{ width: `${width}px` }}
      >
        {titleText && <div className={`${s.modalTitleText} text-primary`}>{titleText}</div>}

        <div className={s.modalContent}>{children}</div>

        <div className={s.modalButtonBlock}>
          {customButtons && customButtons}

          {handleConfirm && (
            <Button onClick={confirmModal} size="medium" disabled={isAvailableConfirm}>
              {textSendButton ? textSendButton : 'Send '}
              <CheckOutlinedIcon className={cs.button__icon} />
            </Button>
          )}

          {handleClose && (
            <Button size="medium" theme="outline" onClick={() => handleClose(false)}>
              {textCloseButton ? textCloseButton : 'Close '}
              <ClearOutlinedIcon className={cs.button__icon} />
            </Button>
          )}
        </div>
      </div>
    </>,
    document.body,
  );
};

export default CustomModal;
