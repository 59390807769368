import React, { useEffect } from 'react';
import { Alert, Button } from '@mui/material';
import { removeNotificationAC } from '../../../store/appReducer';
import { useDispatch } from 'react-redux';
import { notificationEnum, notificationType } from '../../../types/appTypes';

type propsType = {
  item: notificationType;
};

const NotificationItem: React.FC<propsType> = (props) => {
  const { item } = props;

  const dispatch = useDispatch();

  const removeNotification = () => {
    dispatch(removeNotificationAC(item.created_at));
  };

  useEffect(() => {
    setInterval(
      () => {
        removeNotification();
      },
      item.type === notificationEnum.error ? 8000 : 3000,
    );
  }, []);

  return (
    <Alert
      style={{ marginTop: '12px' }}
      severity={item.type}
      color={item.type}
      action={
        <Button color="inherit" size="small" onClick={removeNotification}>
          Close
        </Button>
      }
    >
      {item.description}
    </Alert>
  );
};

export default NotificationItem;
