// export type controllerRequestType = {
//     controller_name: string,
//     controller_key: string,
// }

export enum controllerStatusNamesEnum {
  Online = 'Online',
  Offline = 'Offline',

  Connected = 'Connected',
  Tampered = 'Tampered',
  NotEnabled = 'Not Enabled',

  Unknown = 'Unknown',
}

export type controllersStatusDescriptionsType = {
  [key in controllerStatusNamesEnum]: string;
};
