import {
  HolidayException, HolidayGroupData,
  InstanceHolidayData
} from '../types/holidaysTypes';

enum holidaysGroupReducerTypes {
    initHolidayGroup = 'holidaysGroup/initHolidayGroup',
    addHolidayException = 'holidaysGroup/setHolidayException',
    removeHolidayException = 'holidaysGroup/removeHolidayException',
    setAvailableInstances = 'holidaysGroup/setAvailableInstances',
    removeAvailableInstances = 'holidaysGroup/removeAvailableInstances',
    updateHolidayExceptionSelected = 'holidaysGroup/updateHolidayExceptionSelectedAC',
    updateHolidayExceptionAvailable = 'holidaysGroup/updateHolidayExceptionAvailable',
    updateInstanceName = 'holidaysGroup/updateInstanceName',
    deleteInstanceName = 'holidaysGroup/deleteInstanceName',
    setSelectedGeneralHolidays = 'holidaysGroup/selectedGeneralHolidays',
    setAvailableGeneralHolidays = 'holidaysGroup/setAvailableGeneralHolidays',
    setIsLoading = 'holidaysGroup/setHolidayGroupIsLoading',
}

export const initHolidayGroupAC = (general_holidays: string[], 
  instance_holidays: HolidayGroupData[]) => {
  return {
    type: 'holidaysGroup/initHolidayGroup',
    general_holidays,
    instance_holidays,
  } as const;
};

export const addHolidayExceptionAC = (exception: HolidayException) => {
  return {
    type: 'holidaysGroup/setHolidayException',
    exception,
  } as const;
};

export const removeHolidayExceptionAC = (id: number) => {
  return {
    type: 'holidaysGroup/removeHolidayException',
    id,
  } as const;
};

export const setAvailableInstancesAC = (available_instances: InstanceHolidayData[],
  platforms: string[]) => {
  return {
    type: 'holidaysGroup/setAvailableInstances',
    available_instances,
    platforms,
  } as const;
};

export const removeAvailableInstancesAC = (instance_name: string) => {
  return {
    type: 'holidaysGroup/removeAvailableInstances',
    instance_name,
  } as const;
};

export const updateHolidayExceptionSelectedAC = (id: number,
  selected_holidays: string[]) => {
  return {
    type: 'holidaysGroup/updateHolidayExceptionSelectedAC',
    id,
    selected_holidays,
  } as const;
};

export const updateHolidayExceptionAvailable = (
  available_holidays: string[], instance_name: string) => {
  return {
    type: 'holidaysGroup/updateHolidayExceptionAvailable',
    available_holidays,
    instance_name,
  } as const;
};

export const updateInstanceNameAC = (id: number, 
  instance_name: string, old_instance: InstanceHolidayData) => {
  return {
    type: 'holidaysGroup/updateInstanceName',
    id,
    instance_name,
    old_instance,
  } as const;
};

export const deleteInstanceNameAC = (id: number, instance: InstanceHolidayData) => {
  return {
    type: 'holidaysGroup/deleteInstanceName',
    id,
    instance,
  } as const;
};

export const setSelectedGeneralHolidaysAC = (generalHolidays: string[]) => {
  return {
    type: 'holidaysGroup/selectedGeneralHolidays',
    generalHolidays,
  } as const;
};

export const setAvailableGeneralHolidaysAC = (holidays: string[]) => {
  return {
    type: 'holidaysGroup/setAvailableGeneralHolidays',
    holidays,
  } as const;
};

export const setHolidayGroupIsLoadingAC = (isLoading: boolean) => {
  return {
    type: 'holidaysGroup/setHolidayGroupIsLoading',
    isLoading,
  } as const;
};

export type addHolidayExceptionAT = ReturnType<typeof addHolidayExceptionAC>;
export type removeHolidayExceptionAT = ReturnType<typeof removeHolidayExceptionAC>;
export type setAvailableInstancesAT = ReturnType<typeof setAvailableInstancesAC>;
export type removeAvailableInstancesAT = ReturnType<typeof removeAvailableInstancesAC>;
export type updateHolidayExceptionSelectedAT = ReturnType<typeof updateHolidayExceptionSelectedAC>;
export type updateHolidayExceptionAvailableAT = ReturnType<typeof updateHolidayExceptionAvailable>;
export type updateInstanceNameAT = ReturnType<typeof updateInstanceNameAC>;
export type deleteInstanceNameAT = ReturnType<typeof deleteInstanceNameAC>;
export type setSelectedGeneralHolidaysAT = ReturnType<typeof setSelectedGeneralHolidaysAC>;
export type setAvailableGeneralHolidaysAT = ReturnType<typeof setAvailableGeneralHolidaysAC>;
export type setIsLoadingAT = ReturnType<typeof setHolidayGroupIsLoadingAC>;
export type initHolidayGroupAT = ReturnType<typeof initHolidayGroupAC>;


export type actionType = addHolidayExceptionAT
  | removeHolidayExceptionAT
  | setAvailableInstancesAT
  | removeAvailableInstancesAT
  | updateHolidayExceptionSelectedAT
  | updateHolidayExceptionAvailableAT
  | updateInstanceNameAT
  | deleteInstanceNameAT
  | setSelectedGeneralHolidaysAT
  | setAvailableGeneralHolidaysAT
  | setIsLoadingAT
  | initHolidayGroupAT


type InitStateType = {
    exceptions: HolidayException[];
    available_instances: InstanceHolidayData[];
    selectedGeneralHolidays: string[];
    availableGeneralHolidays: string[];
    platforms: string[];
    currentExceptionId: number;
    isLoading: boolean;
}

export const initState: InitStateType = {
  exceptions: [],
  currentExceptionId: 0,
  available_instances: [],
  platforms: [],
  availableGeneralHolidays: [],
  selectedGeneralHolidays: [],
  isLoading: false,
};


export const holidaysGroupReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case holidaysGroupReducerTypes.initHolidayGroup:
    return {
      ...state,
      availableGeneralHolidays: action.general_holidays,
      available_instances: action.instance_holidays,
      exceptions: [],
      selectedGeneralHolidays: [],
    };
  case holidaysGroupReducerTypes.addHolidayException:
    return {
      ...state,
      exceptions: [
        ...state.exceptions,
        {
          ...action.exception,
          id: state.currentExceptionId,
        },
      ],
      currentExceptionId: state.currentExceptionId + 1,
    };
  case holidaysGroupReducerTypes.removeHolidayException:
    const updatedAvailableInstances = state.exceptions.find((item) =>
      item.id === action.id)?.holiday_group_data;

    return {
      ...state,
      exceptions: state.exceptions.filter((item) => item.id !== action.id),
      available_instances: [
        ...state.available_instances,
        updatedAvailableInstances ?? { instance_name: '', holidays: [] },
      ],
    };
  case holidaysGroupReducerTypes.setAvailableInstances:
    return {
      ...state,
      available_instances: action.available_instances,
      exceptions: [],
      platforms: action.platforms,
    };
  case holidaysGroupReducerTypes.removeAvailableInstances:  
    return {
      ...state,
      available_instances: state.available_instances.filter((item) =>
        item.instance_name !== action.instance_name),
    };
  case holidaysGroupReducerTypes.updateHolidayExceptionSelected:
    return {
      ...state,
      exceptions: state.exceptions.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            selected_holidays: action.selected_holidays,
          };
        }
        return item;
      }),
    };
  case holidaysGroupReducerTypes.updateHolidayExceptionAvailable:
    return {
      ...state,
      exceptions: state.exceptions.map((item) => {
        if (item.holiday_group_data.instance_name === action.instance_name) {
          return {
            ...item,
            available_holidays: action.available_holidays,
          };
        }
        return item;
      }),
      isLoading: false,
    };
  case holidaysGroupReducerTypes.updateInstanceName:
    const updatedHolidays = state.available_instances.find((item) =>
      item.instance_name === action.instance_name)?.holidays;

    const oldInstanceHolidays = state.available_instances.find(
      (instance) => instance.instance_name === action.old_instance.instance_name
    )?.holidays;

    const addedToAvailable = action.old_instance.instance_name ? [{
      instance_name: action.instance_name,
      holidays: oldInstanceHolidays ?? []
    }] : [];

    return {
      ...state,
      exceptions: state.exceptions.map((item) => {
        if (item.id === action.id) {
          return {
            ...item,
            holiday_group_data: {
              holidays: updatedHolidays ?? [],
              instance_name: action.instance_name,
            },
          };
        }
        return item;
      }),
      available_instances: [
        ...state.available_instances.filter(
          (instance) => instance.instance_name !== action.instance_name
        ),
        ...addedToAvailable
      ],
    };  
  case holidaysGroupReducerTypes.deleteInstanceName:
    return {
      ...state,
      exceptions: state.exceptions.map((item) => {
        if (item.holiday_group_data.instance_name === action.instance.instance_name) {
          return {
            ...item,
            holiday_group_data: {
              instance_name: '',
              holidays: [],
            },
          };
        }
        return item;
      }),
      available_instances: action.instance.instance_name
        ? [...state.available_instances, action.instance]
        : state.available_instances,
    };
  case holidaysGroupReducerTypes.setSelectedGeneralHolidays:
    return {
      ...state,
      selectedGeneralHolidays: action.generalHolidays,
    };
  case holidaysGroupReducerTypes.setAvailableGeneralHolidays:
    return {
      ...state,
      availableGeneralHolidays: action.holidays,
      isLoading: false,
    };
  case holidaysGroupReducerTypes.setIsLoading:
    return {
      ...state,
      isLoading: action.isLoading,
    };
  default:
    return state;
  }
};