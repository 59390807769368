import { DaysOfWeek, ScheduleMainDataType, ScheduleFullDataType } from '../types/schedulesTypes';
import { utils_instance } from '../utils/Utils';

enum schedulesReducerTypes {
  setTemporarySchedulesData = 'schedules/setTemporarySchedulesData',
  setCurrentlySchedulesData = 'schedules/setCurrentlySchedulesData',
  updateCurrentlySchedulesData = 'schedules/updateCurrentlySchedulesData',
  setHoldSchedulesData = 'schedules/setHoldSchedulesData',
}

export const setTemporarySchedulesDataAC = (
  schedulesData: ScheduleMainDataType[],
  timezone: string,
  hashSum?: string
) => {
  //dispatch temporary schedules list
  return {
    type: schedulesReducerTypes.setTemporarySchedulesData,
    schedulesData,
    timezone,
    hashSum
  } as const;
};

export const setCurrentlySchedulesDataAC = (
  schedulesData: ScheduleMainDataType[],
  timezone: string,
  hashSum?: string
) => {
  //dispatch currently schedules list
  return {
    type: schedulesReducerTypes.setCurrentlySchedulesData,
    schedulesData,
    timezone,
    hashSum
  } as const;
};

export const setHoldSchedulesDataAC = (
  schedulesData: ScheduleMainDataType[], 
  timezone: string,
  hashSum?: string
) => {
  //dispatch temporary schedules list
  return {
    type: schedulesReducerTypes.setHoldSchedulesData,
    schedulesData,
    timezone,
    hashSum
  } as const;
};

//TODO refactor
export const updateCurrentlySchedulesDataAC = (
  timezone: string
) => {
  return {
    type: schedulesReducerTypes.updateCurrentlySchedulesData,
    timezone
  } as const;
};

export type setTemporarySchedulesDataAT = ReturnType<typeof setTemporarySchedulesDataAC>;
export type setCurrentlySchedulesDataAT = ReturnType<typeof setCurrentlySchedulesDataAC>;
export type updateCurrentlySchedulesDataAT = ReturnType<typeof updateCurrentlySchedulesDataAC>;

export type setHoldSchedulesDataAT = ReturnType<typeof setHoldSchedulesDataAC>;

export type actionType =
  | setTemporarySchedulesDataAT
  | setCurrentlySchedulesDataAT
  | updateCurrentlySchedulesDataAT
  | setHoldSchedulesDataAT


type InitStateType = {
  temporarySchedulesData: ScheduleFullDataType[];
  currentlySchedulesData: ScheduleFullDataType[];
  holdScheduleData: ScheduleFullDataType[];
  hashSum: string;
};

export const initState: InitStateType = {
  temporarySchedulesData: [],
  currentlySchedulesData: [],
  holdScheduleData: [],
  hashSum: '',
};

// TODO refactor merge two reducer
export const schedulesReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case schedulesReducerTypes.setTemporarySchedulesData: {
    if (state.hashSum === action.hashSum) {
      return state;
    }
    const copyState = { ...state };
    if (action.hashSum){
      copyState.hashSum = action.hashSum;
    }
    const currentBuildingTime = utils_instance.getCurrentBuildingTime(action.timezone);

    copyState.temporarySchedulesData = action.schedulesData.map((item) => {
      const type = item.is_from_holiday_schedule
        ? 'Holiday schedule'
        : item.is_from_mode_all_schedule
          ? 'Mode all Schedule'
          : item.days_of_week
            ? 'Regular Schedule'
            : 'Simple Schedule';

      return {
        ...item,
        mode: item.mode.replace('Schedule: ', ''),

        days_of_week: item.days_of_week
          ? item.days_of_week.map((days) => DaysOfWeek[+days])
          : null,

        schedule_visible_init_date:
            utils_instance.parseFullDateForSchedule(
              new Date(item.init_date.substring(0, item.init_date.length - 6))),
        schedule_visible_end_date:
            utils_instance.parseFullDateForSchedule(
              new Date(item.end_date.substring(0, item.end_date.length - 6))),

        schedule_time_left: utils_instance
          .getTimeLeft(
            currentBuildingTime,
            new Date(item.end_date.substring(0, item.end_date.length - 6))),
        type: type,
      };
    });
    return copyState;
  }
  case schedulesReducerTypes.setCurrentlySchedulesData: {
    // if (state.hashSum === action.hashSum) {
    //   return state;
    // }
    const copyState = { ...state };
    if (action.hashSum){
      copyState.hashSum = action.hashSum;
    }
    const currentBuildingTime = utils_instance.getCurrentBuildingTime(action.timezone);

    copyState.currentlySchedulesData = action.schedulesData.map((item) => {
      const type = item.is_from_holiday_schedule
        ? 'Holiday schedule'
        : item.is_from_mode_all_schedule
          ? 'Mode all Schedule'
          : item.days_of_week
            ? 'Regular Schedule'
            : 'Simple Schedule';

      return {
        ...item,
        mode: item.mode.replace('Schedule: ', ''),

        days_of_week: item.days_of_week
          ? item.days_of_week.map((days) => DaysOfWeek[+days])
          : null,

        schedule_visible_init_date:
            utils_instance.parseFullDateForSchedule(
              new Date(item.init_date.substring(0, item.init_date.length - 6))),
        schedule_visible_end_date:
            utils_instance.parseFullDateForSchedule(
              new Date(item.end_date.substring(0, item.end_date.length - 6))),

        schedule_time_left: utils_instance
          .getTimeLeft(
            currentBuildingTime,
            new Date(item.end_date.substring(0, item.end_date.length - 6))),
        type: type,
      };
    });

    return copyState;
  }
  case schedulesReducerTypes.setHoldSchedulesData: {
    if (state.hashSum === action.hashSum) {
      return state;
    }
    const copyState = { ...state };

    if (action.hashSum){
      copyState.hashSum = action.hashSum;
    }
    const currentBuildingTime = utils_instance.getCurrentBuildingTime(action.timezone);

    copyState.holdScheduleData = action.schedulesData.map((item) => {
      const type = item.is_from_holiday_schedule
        ? 'Holiday schedule'
        : item.is_from_mode_all_schedule
          ? 'Mode all Schedule'
          : item.days_of_week
            ? 'Regular Schedule'
            : 'Simple Schedule';

      return {
        ...item,
        mode: item.mode.replace('Schedule: ', ''),

        days_of_week: item.days_of_week
          ? item.days_of_week.map((days) => DaysOfWeek[+days])
          : null,

        schedule_visible_init_date:
            utils_instance.parseFullDateForSchedule(
              new Date(item.init_date.substring(0, item.init_date.length - 6))),
        schedule_visible_end_date:
            utils_instance.parseFullDateForSchedule(
              new Date(item.end_date.substring(0, item.end_date.length - 6))),

        schedule_time_left: utils_instance
          .getTimeLeft(
            currentBuildingTime,
            new Date(item.end_date.substring(0, item.end_date.length - 6))),
        type: type,
      };
    });
    return copyState;
  }
  case schedulesReducerTypes.updateCurrentlySchedulesData: {
    const copyState = { ...state };

    const currentBuildingTime = utils_instance.getCurrentBuildingTime(action.timezone);

    copyState.currentlySchedulesData = state.currentlySchedulesData
      .map((dateObj) => {
        const draftVisibleEndDate =
            new Date(dateObj.end_date.substring(0, dateObj.end_date.length - 6));
        const timeLeft = utils_instance.getTimeLeft(currentBuildingTime, draftVisibleEndDate);
        return {
          ...dateObj,
          timeLeft: timeLeft,
        };
      })
      .filter((dateObj) => dateObj.timeLeft > 0);

    return copyState;
  }
  default: {
    return state;
  }
  }
};
