export enum websocketPageEnum {
  readers = 'readers',
  cameras = 'cameras',
  intercoms = 'intercoms',
  hardware_users = 'hardware_users',
  instance_group_schedules = 'instance_group_schedules',
  counting_areas = 'counting_areas',
  other = 'other',
}

export enum websocketEventEnum {
  readers_status = 'readers_status',
  schedule = 'schedule',
  instance_group_schedules = 'instance_group_schedules',
  camera_status = 'camera_status',
  intercom_status = 'intercom_status',
  hardware_user_server = 'hardware_user_server',

  disconnect_message = 'disconnect_message',
  people_count = 'people_count',
  access_level = 'access_level',
  alert_status = 'alert_status',

  other = 'other',
}
