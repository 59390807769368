import { sortKeyEnum } from './tableTemplateTypes';
import { RolesEnum } from './types';

export enum PulseStatusEnum {
  idle = 'idle',
  wait = 'wait',
  timer = 'timer',
}

export enum ReaderAdminActionEnum {
  lock_all = 'Lock All',
  reset_all = 'Reset All',
}

export enum readerStatusNamesEnum {
  NoAccessMethod = 'No Access Method',
  CardOnly = 'Card Only',
  PinOnly = 'PIN Only',
  CardAndPin = 'Card and PIN',
  CardOrPin = 'Card or PIN',
  FacilityCodeOnly = 'Facility Code Only',
  Unlocked = 'Unlocked',
  LockedDown = 'Locked Down',

  ClosedCardRead = 'Closed Card Read',
  DelayOnEgress = 'Delay on Egress',
  Disabled = 'Disabled',
  DogOnNextExit = 'Dog On Next Exit',
  ExtendedUnlock = 'Extended Unlock',
  Forced = 'Forced',
  Held = 'Held',
  Init = 'Init',
  Privacy = 'Privacy',
  Ready = 'Ready',
  ShuntedClosed = 'Shunted Closed',
  ShuntedOpen = 'Shunted Open',

  Unknown = 'Unknown',
}

export type readersStatusDescriptionsType = {
  [key in readerStatusNamesEnum]: string;
};

// export type UserPermissions = {
//     LockAllowed: boolean
//     UnlockAllowed: boolean
//     ResetAllowed: boolean
//     PulseAllowed: boolean
//     ModeAllowed: boolean
//     RebootAllowed: boolean
// }

export type sortInfoType = {
  keySort: sortKeyEnum;
  directionSort: number;
};

export type HardwarePermissionsType = {
  //common type for permission for hardware
  reader_lock_permission: boolean;
  reader_unlock_permission: boolean;
  reader_reset_permission: boolean;
  reader_pulse_permission: boolean;
  schedule_mode_permission: boolean;
  controller_reboot_permission: boolean;
};

export type HardwareCommonStatusType = {
  //base hardware common status"""
  StatusValue: number; //TODO fix
  StatusName: string; //TODO Union[DownstreamStatusesEnum, ControllersStatusesEnum, ReadersStatusesEnum]
};

export type ReadersStatus = HardwareCommonStatusType & {
  //status type for reader hardware
  Mode?: string;
  IsTampered?: boolean;
  TamperMessage?: string;
  IsOnlDoorForcedOpen?: boolean;
  DoorHeldOpen?: boolean;
  DoorContactMessage?: string;
  DoorContactFaulted?: boolean;
  RexFaulted?: boolean;
  RexMessage?: string;
  IsOnline?: boolean;
  CommunicationMessage?: string;
  IsPowered?: boolean;
};

export type HardwareMainDataType = {
  //main hardware data validate hardware for actions
  Key: string;
  CommonName: string;
  ControllerKey: string;
  ControllerCommonName: string;
};

export type HardwareNicknameDataType = {
  hardware_id: string;
  nickname: string;
  created_by: string;
  created_at: string;
};

export type HardwareCommonDataType = HardwareMainDataType & {
  //common hardware data for response
  Status: ReadersStatus | HardwareCommonStatusType;
  InstanceName: string;
  HardwarePermissions: HardwarePermissionsType;
  IsFavorite: boolean;
  NicknameData?: HardwareNicknameDataType;
};

export type HardwareReaderDataType = HardwareCommonDataType & {
  //hardware reader data
  PulseTimer: number;
  IsElevator: boolean;
};

export type HardwareControllerData = HardwareCommonDataType & {
  //hardware controller data
  IpAddress?: string;
  UniqueIdentifier?: string;
  BuildingId?: number;
  SubnetId?: number;
  SwitchName?: string;
  SwitchDescription?: string;
  SwitchIp?: string;
  SwitchPort?: number;
  Floor?: string;
  Vlan?: string;
};

export type HardwareReadersResponseType = {
  //hardware readers list response
  Hardware: Array<HardwareReaderDataType>;
};

export type HardwareDownstreamResponseType = {
  //hardware downstream list response
  Hardware: Array<HardwareCommonDataType>;
};

export type HardwareControllersResponseType = {
  //hardware controllers list response
  Hardware: Array<HardwareControllerData>;
};

export type HardwareActionResponseType = {
  //common response for action with hardware device
  status_code: number;
  detail: string;
  action: string; //TODO fix
  device: string;
};

export type HardwareActionsListResponse = {
  //common response for actions list with all hardware device
  statuses: HardwareActionResponseType[];
};

export type readersActionsResponse = {
  Hardware: Array<HardwareReaderDataType>;
  UserRole: RolesEnum;
  statuses: string[];
};

export type statusesType = {
  //need for return readers statuses dict. key = reader_key, value = status
  [key: string]: ReadersStatus | HardwareCommonStatusType;
};

export type HardwareReadersStatusesResponseType = {
  //need for return readers statuses dict. key = reader_key, value = status
  statuses: statusesType;
  platform: string;
};

export type HardwareReaderLogDataType = {
  log_id: string;
  user_uuid?: string;
  user_email?: string;
  init_date: string;
  log_type?: string;
  reason?: string;
};

export type HardwareReaderLogsListResponseType = {
  //hardware log action response
  HardwareLogs: HardwareReaderLogDataType[];
};

export type HardwareActionCommonRequestType = {
  //common action request for hardware device
  hardware_key: string;
};

export type HardwareActionWithFloorRequestType = HardwareActionCommonRequestType & {
  //action request for hardware device with floor
  floor?: number;
};

// export type payloadScheduleType = { //payloadReaderType & {
//     floor: number,
//     minutes: number,
//     mode: number,
//     schedule: number,
//     days_of_week?: string[],
// }

export type ScheduleActionDataType = {
  // """data about schedule after set schedule"""
  item_id: string;
  result: string; //TODO check (add ENUM)
};

export type ScheduleActionsResponseType = {
  // need for get return list of responses after set schedule
  schedule_action_list: ScheduleActionDataType[];
};

export type FloorsResponseType = {
  //response for receive list of floors by readers
  floors: number[];
};

export type FloorDataType = {
  //floor data type
  FloorName: string;
  FloorValue: number;
};

// export type HardwareStatusDescriptionType = {
//     id: number
//     status: string
//     description: string
// }
//
// export type readersStatusesDescriptionType = {
//     [key: string]: string
// }

export enum readersActionNamesEnum {
  cardOnly = 'Card Only',
  unlock = 'Unlock',
  reset = 'Reset',
  pulse = 'Pulse',
  mode = 'Mode',
  cardOnlyAll = 'Card Only All',
  resetAll = 'Reset All',
  modeAll = 'Mode All',
  logsList = 'Logs',
}

export type readersActionDescriptionsType = {
  [key in readersActionNamesEnum]: string;
};

export type SchedulesTaskIDsListType = {
  //need for delete schedules tasks
  tasks_id_list: string[];
};

export type ScheduleTaskIDType = {
  task_start_id: string;
  reader_key: string;
};

export type readersTaskIdType = {
  task_id: string;
};
