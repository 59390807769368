import {
  HardwareUserDataType,
  ServerPublicDataType,
  ServerUserDataType,
} from '../types/HardwareUsersTypes';

enum HardwareUsersReducerType {
  setServersPublicList = 'hardwareUsers/setServersPublicList',
  setServersPublicCount = 'hardwareUsers/setServersPublicCount',
  setHardwareUserData = 'hardwareUsers/setHardwareUsers',
  setServersUserList = 'hardwareUsers/setServersUserList',
  addHardwareUserServer = 'hardwareUsers/addHardwareUserServer',
  decrementServersPublicCount = 'hardwareUsers/decrementServersPublicCount',
}

export const setServersPublicListAC = (serversPublicList: ServerPublicDataType[]) => {
  //dispatch available servers list
  return {
    type: HardwareUsersReducerType.setServersPublicList,
    serversPublicList,
  } as const;
};

export const setServersPublicCountAC = (serversPublicCount: number) => {
  //dispatch available servers count
  return {
    type: HardwareUsersReducerType.setServersPublicCount,
    serversPublicCount,
  } as const;
};

export const setHardwareUserDataAC = (hardwareUser: HardwareUserDataType | null) => {
  //dispatch set hardware user that was found by uuid
  return {
    type: HardwareUsersReducerType.setHardwareUserData,
    hardwareUser,
  } as const;
};

export const setServersUserListAC = (serversUserList: ServerPublicDataType[]) => {
  //dispatch user servers list
  return {
    type: HardwareUsersReducerType.setServersUserList,
    serversUserList,
  } as const;
};

export const addHardwareUserServerAC = (
  server_name: string,
  user_key: string,
  is_exist: number,
) => {
  //dispatch add hardware server as exist
  return {
    type: HardwareUsersReducerType.addHardwareUserServer,
    server_name,
    user_key,
    is_exist,
  } as const;
};

export const decrementServersPublicCountAC = (user_key: string) => {
  //dispatch decrement item from available servers count
  return {
    type: HardwareUsersReducerType.decrementServersPublicCount,
    user_key,
  } as const;
};

export type setServersPublicListAT = ReturnType<typeof setServersPublicListAC>;
export type setServersPublicCountAT = ReturnType<typeof setServersPublicCountAC>;
export type setHardwareUserDataAT = ReturnType<typeof setHardwareUserDataAC>;
export type setServersUserListAT = ReturnType<typeof setServersUserListAC>;
export type addHardwareUserServerAT = ReturnType<typeof addHardwareUserServerAC>;
export type decrementServersPublicCountAT = ReturnType<typeof decrementServersPublicCountAC>;

export type actionType =
  | setServersPublicListAT
  | setServersPublicCountAT
  | setHardwareUserDataAT
  | setServersUserListAT
  | addHardwareUserServerAT
  | decrementServersPublicCountAT;

type InitStateType = {
  serversPublicList: ServerPublicDataType[];
  serversPublicCount: number;
  hardwareUser: HardwareUserDataType | null;
  serversUserList: ServerUserDataType[];
};

export const initState: InitStateType = {
  serversPublicList: [],
  serversPublicCount: 0,
  hardwareUser: null,
  serversUserList: [],
};

export const hardwareUsersReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case HardwareUsersReducerType.setServersPublicList: {
    const copyState = { ...state };

    copyState.serversPublicList = action.serversPublicList;

    return copyState;
  }
  case HardwareUsersReducerType.setServersPublicCount: {
    const copyState = { ...state };

    copyState.serversPublicCount = action.serversPublicCount;

    return copyState;
  }
  case HardwareUsersReducerType.setHardwareUserData: {
    const copyState = { ...state };

    copyState.hardwareUser = action.hardwareUser;

    return copyState;
  }
  case HardwareUsersReducerType.setServersUserList: {
    const copyState = { ...state };

    copyState.serversUserList = action.serversUserList.map((server) => {
      return { ...server, is_exist: 0 };
    });

    return copyState;
  }
  case HardwareUsersReducerType.addHardwareUserServer: {
    const copyState = { ...state };

    if (!copyState.hardwareUser) {
      return copyState;
    }
    if (copyState.hardwareUser.user_key !== action.user_key) {
      return copyState;
    }
    copyState.serversUserList = copyState.serversUserList.map((server) => {
      if (server.server_name === action.server_name) {
        return { ...server, is_exist: action.is_exist };
      } else {
        return server;
      }
    });
    return copyState;
  }
  case HardwareUsersReducerType.decrementServersPublicCount: {
    const copyState = { ...state };

    if (!copyState.hardwareUser) {
      return copyState;
    }

    if (copyState.hardwareUser.user_key !== action.user_key) {
      return copyState;
    }

    if (copyState.hardwareUser.user_key === action.user_key) {
      copyState.serversPublicCount = copyState.serversPublicCount - 1;
    }

    return copyState;
  }
  default: {
    return state;
  }
  }
};
