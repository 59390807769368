import {
  FloorDataType,
  HardwareReaderDataType,
  HardwareReadersStatusesResponseType,
  sortInfoType,
} from '../types/readersTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { utils_instance } from '../utils/Utils';

enum readersReducerTypes {
    setHardwareReadersDataList = 'readers/setHardwareReadersDataList',
    setSortReadersData = 'readers/setSortReadersDataAC',
    updateReadersStatuses = 'readers/updateReadersStatuses',
    setFloorsList = 'readers/setFloorsList',
    setSelectedFloor = 'readers/setSelectedFloor',
}

export const setHardwareReadersDataListAC = (hardwareReadersDataList: HardwareReaderDataType[]) => {
  //dispatch main hardware readers data list
  return {
    type: readersReducerTypes.setHardwareReadersDataList,
    hardwareReadersDataList,
  } as const;
};
export const setSortReadersDataAC = (keySort: sortKeyEnum) => {
  //dispatch sort readers data
  return {
    type: readersReducerTypes.setSortReadersData,
    keySort,
  } as const;
};
export const updateReadersStatusesAC = (
  ReadersStatuses: HardwareReadersStatusesResponseType,
  hashSum?: string
) => {
  //dispatch update  readers statuses
  return {
    type: readersReducerTypes.updateReadersStatuses,
    ReadersStatuses,
    hashSum,
  } as const;
};
export const setFloorsListAC = (floorsList: number[]) => {
  //dispatch set floors list
  return {
    type: readersReducerTypes.setFloorsList,
    floorsList,
  } as const;
};
export const setSelectedFloorAC = (selectedFloor: number) => {
  //dispatch selected global floor for floor filter
  return {
    type: readersReducerTypes.setSelectedFloor,
    selectedFloor,
  } as const;
};

export type setHardwareReadersDataListAT = ReturnType<typeof setHardwareReadersDataListAC>;
export type setSortReadersDataAT = ReturnType<typeof setSortReadersDataAC>;
export type updateReadersStatusesAT = ReturnType<typeof updateReadersStatusesAC>;
export type setFloorsListAT = ReturnType<typeof setFloorsListAC>;
export type setSelectedFloorAT = ReturnType<typeof setSelectedFloorAC>;



export type actionType =
    | setHardwareReadersDataListAT
    | setSortReadersDataAT
    | updateReadersStatusesAT
    | setFloorsListAT
    | setSelectedFloorAT;

type InitStateType = {
    hardwareReadersDataList: HardwareReaderDataType[];
    sortInfo: sortInfoType;
    floorsList: FloorDataType[];
    selectedFloor: number;
    hashSum: string
};

export const initState: InitStateType = {
  hardwareReadersDataList: [],
  sortInfo: {
    keySort: sortKeyEnum.Status,
    directionSort: 1,
  },
  floorsList: [],
  selectedFloor: 0,
  hashSum: ''
};

export const readersReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case readersReducerTypes.setHardwareReadersDataList: {
    const copyState = { ...state };

    const listFavorites: HardwareReaderDataType[] = [];
    const listNotFavorites: HardwareReaderDataType[] = [];

    action.hardwareReadersDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareReadersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareReadersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.hardwareReadersDataList = [...sortFavorites, ...sortNotFavorites];

    return copyState;
  }
  case readersReducerTypes.setSortReadersData: {
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
                state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    const listFavorites: HardwareReaderDataType[] = [];
    const listNotFavorites: HardwareReaderDataType[] = [];

    state.hardwareReadersDataList.forEach((item) => {
      if (item.IsFavorite) {
        listFavorites.push(item);
      } else {
        listNotFavorites.push(item);
      }
    });

    const sortFavorites = utils_instance.sortHardwareReadersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listFavorites,
    );
    const sortNotFavorites = utils_instance.sortHardwareReadersDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      listNotFavorites,
    );

    copyState.hardwareReadersDataList = [...sortFavorites, ...sortNotFavorites];
    copyState.sortInfo.keySort = action.keySort;

    return copyState;
  }
  case readersReducerTypes.updateReadersStatuses: {
    if (state.hashSum === action.hashSum) {
      return state;
    }
    const copyState = { ...state };
    if (action.hashSum) {
      copyState.hashSum = action.hashSum;
    }
    copyState.hardwareReadersDataList = [
      ...state.hardwareReadersDataList,
    ].map((reader) => {
      if (action.ReadersStatuses.statuses[reader.Key]) {
        return {
          ...reader,
          Status: action.ReadersStatuses.statuses[reader.Key],
        };
      }
      return reader;
    });

    return copyState;
  }
  case readersReducerTypes.setFloorsList: {
    const copyState = { ...state };

    copyState.floorsList = action.floorsList.map((item) => {
      return {
        FloorName: item === 0 ? 'All Floors' : `${item} Floor`,
        FloorValue: item,
      };
    });

    return copyState;
  }
  case readersReducerTypes.setSelectedFloor: {
    const copyState = { ...state };
    copyState.selectedFloor = action.selectedFloor;
    return copyState;
  }
  default: {
    return state;
  }
  }
};
