import React, { useEffect, useState } from 'react';
import cs from './styles/commonStyles.module.css';
import './App.css';
import './styles/components.css';
import { useAuth0 } from '@auth0/auth0-react';
import CircularProgress from '@mui/material/CircularProgress';
import { BrowserRouter } from 'react-router-dom';
import AuthProvider from './auth/authProvider';
import { Provider, useDispatch, useSelector } from 'react-redux';
import { AppRootStateType, store } from './store/store';
import RouterPageWrapper from './components/pages/RouterPage/RouterPage';
import { ApiInstance } from './api/api';
import { setTokenAC } from './store/authReducer';
import { utils_instance } from './utils/Utils';

const _App: React.FC = () => {
  const dispatch = useDispatch();

  const { isAuthenticated, loginWithRedirect, getAccessTokenSilently, logout } = useAuth0();

  const token = useSelector<AppRootStateType, string | null>((state) => state.auth.token);

  const auth = localStorage.getItem('auth');

  const [load, setLoad] = useState<boolean>(true);

  const myLogout = () => {
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const loadData = async () => {
    if (!isAuthenticated && !auth) {
      localStorage.setItem('auth', '1');
      loginWithRedirect();
    }

    if (auth && !isAuthenticated) {
      localStorage.removeItem('auth');
    }

    if (isAuthenticated) {
      const newToken = await getAccessTokenSilently();
      dispatch(setTokenAC(newToken));
    }

    if (token) {
      utils_instance.checkIsValidToken(token, myLogout);
      ApiInstance.createAxiosInstance(token);
      setLoad(false);
    }
  };

  useEffect(() => {
    loadData();
  }, [auth, isAuthenticated, token]);

  if (!token || load) {
    return (
      <div className={cs.position__center}>
        <CircularProgress />
      </div>
    );
  }

  return <RouterPageWrapper />;
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <Provider store={store}>
          <_App />
        </Provider>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;