import React, { useEffect, useState } from 'react';
import cs from '../../../styles/commonStyles.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import CustomModal from '../../templates/CustomModal/CustomModal';
import MenuItem from '@mui/material/MenuItem';
import { InstancePublicDataType, selectInstanceModeEnum } from '../../../types/appTypes';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import StorageIcon from '@mui/icons-material/Storage';
import PublicIcon from '@mui/icons-material/Public';
import { Button } from '@wework/ray2';
import { Autocomplete, TextField } from '@mui/material';
import s from '../../templates/CustomModal/CustomModal.module.css';
import DeleteIcon from '@mui/icons-material/Delete';
import {
  selectInstanceModeType,
  setCurrentInstanceDataAC,
  setSelectInstanceModeAC,
} from '../../../store/appReducer';
import { utils_instance } from '../../../utils/Utils';
import { useCurrentInstanceData } from '../../../store/selectors/useCurrentInstanceData';

type propsType = {
  setShowModalInstance?: (value: boolean) => void;
  selectToDefault?: boolean;
};

const selectInstanceButtons = [
  {
    key: 'search',
    selectInstanceMode: selectInstanceModeEnum.searchMode,
    label: 'Search',
    icon: <SearchOutlinedIcon className={cs.button__icon} />,
  },
  {
    key: 'region',
    selectInstanceMode: selectInstanceModeEnum.regionMode,
    label: 'Region',
    icon: <PublicIcon className={cs.button__icon} />,
  },
  {
    key: 'territory',
    selectInstanceMode: selectInstanceModeEnum.territoryMode,
    label: 'Territory',
    icon: <SouthAmericaIcon className={cs.button__icon} />,
  },
  {
    key: 'server_name',
    selectInstanceMode: selectInstanceModeEnum.serverMode,
    label: 'Server',
    icon: <StorageIcon className={cs.button__icon} />,
  },
  {
    key: 'instance_groups',
    selectInstanceMode: selectInstanceModeEnum.instanceGroup,
    label: 'Instance Group',
    icon: <StorageIcon className={cs.button__icon} />,
  }
];

const SelectInstance: React.FC<propsType> = (props) => {
  const { setShowModalInstance, selectToDefault } = props;

  const dispatch = useDispatch();
  const currentInstanceData = useCurrentInstanceData();

  const selectInstanceMode = useSelector<AppRootStateType, selectInstanceModeType>(
    (state) => state.app.selectInstanceMode,
  );
  const instancesList = useSelector<AppRootStateType, InstancePublicDataType[]>(
    (state) => state.app.instancesList,
  );

  const [temporalInstanceData, setTemporalInstanceData] = useState<InstancePublicDataType | null>(
    null,
  );
  const [temporalInstancesList, setTemporalInstancesList] = useState<InstancePublicDataType[]>([]);

  const [searchInputValue, setSearchInputValue] = React.useState<string>('');

  const onChangeSelectInstanceMode = (
    key: string,
    selectInstanceMode: selectInstanceModeEnum,
    label: string,
  ) => {
    //switch button mode
    dispatch(
      setSelectInstanceModeAC({
        selectInstanceMode,
        key,
        value: temporalInstanceData
          ? (key === 'instance_groups' && Array.isArray(temporalInstanceData[key])
            ? (temporalInstanceData[key].length !== 0
              ? temporalInstanceData[key][0]
              : '')
            : temporalInstanceData[key as keyof InstancePublicDataType])
          : '',
        label,
      }),
    );
  };

  const onChangeMenuFirstFieldItem = (e: SelectChangeEvent<string | number | string[]>) => {
    //change value in first field
    dispatch(
      setSelectInstanceModeAC({
        ...selectInstanceMode,
        value: e.target.value,
      }),
    );
    setTemporalInstanceData(null);
    setSearchInputValue('');
  };

  const onChangeMenuInstanceData = (e: SelectChangeEvent<string | number>) => {
    //change value in second field
    const instance = temporalInstancesList.find((instance) => instance.id === e.target.value);
    instance && setTemporalInstanceData(instance);
    instance && setSearchInputValue(instance.instance_name);
  };

  const onChangeInputValue = (newInputValue: string, event: any) => {
    //change search field
    event && setSearchInputValue(newInputValue);
  };

  const confirmSelect = () => {
    //confirm select and set temporal instance as current instance
    if (temporalInstanceData) {
      if (selectToDefault) {
        localStorage.setItem('savedInstance', JSON.stringify(temporalInstanceData));
      } else {
        dispatch(setCurrentInstanceDataAC(temporalInstanceData));
      }
    }
    setShowModalInstance && setShowModalInstance(false);
  };

  const removeDefaultInstance = () => {
    //need for remove default instance
    localStorage.removeItem('savedInstance');
    setTemporalInstanceData(null);
    setSearchInputValue('');
    dispatch(
      setSelectInstanceModeAC({
        ...selectInstanceMode,
        value: '',
      }),
    );
  };

  const onChangeHandlerInSearch = (newValue: InstancePublicDataType | null) => {
    setTemporalInstanceData(newValue);
    setSearchInputValue(newValue ? newValue.instance_name : '');
  };

  const menuFirstFieldItems = (key: string) => {
    let menuList: (string | number | string[])[];
    let uniqueItems = Array.from(
      new Set(instancesList.map((instance) => instance[key as keyof InstancePublicDataType])),
    );
    if (key === 'instance_groups'){
      const flattenArray = uniqueItems.flat();
      menuList = Array.from(new Set(flattenArray));
    }else{
      menuList = uniqueItems;
    }
    return menuList
      .sort((a, b) => (a > b ? 1 : -1))
      .map((item, index) => {
        let list = [];
        if (key === 'instance_groups' && typeof item === 'string'){
          list = instancesList.filter(
            (instance) => instance['instance_groups'].includes(item),
          );
        }else {
          list = instancesList.filter(
            (instance) => instance[key as keyof InstancePublicDataType] === item,
          );
        }
        return (
          <MenuItem key={index} value={item} disabled={!list.length}>
            {item}
          </MenuItem>
        );
      });
  };

  const menuInstancesItems = temporalInstancesList.map((instance) => {
    return (
      <MenuItem key={instance.id} value={instance.id}>
        {instance.instance_name}
      </MenuItem>
    );
  });

  useEffect(() => {
    if (currentInstanceData) {
      let savedInstanceData = {} as InstancePublicDataType;
      if (selectToDefault) {
        const savedInstance = localStorage.getItem('savedInstance');
        if (savedInstance) {
          savedInstanceData = {
            ...savedInstanceData,
            ...JSON.parse(savedInstance),
          };
        }
      }
      const validInstance = utils_instance.validateParams(
        savedInstanceData.platform,
        savedInstanceData.server_id,
        savedInstanceData.instance_id,
        instancesList,
      );
      const finalInstance = validInstance ? validInstance : currentInstanceData;
      setTemporalInstanceData(finalInstance);
      setSearchInputValue(finalInstance.instance_name);
      dispatch(
        setSelectInstanceModeAC({
          ...selectInstanceMode,
          value: selectInstanceMode.key === 'instance_groups'
          && Array.isArray(finalInstance[selectInstanceMode.key as keyof InstancePublicDataType])?
            finalInstance[selectInstanceMode.key][0]
            : finalInstance[selectInstanceMode.key as keyof InstancePublicDataType],
        }),
      );
    }
  }, [currentInstanceData]);

  useEffect(() => {
    //select instance list depend on first field
    let instances;
    if (selectInstanceMode.key === 'instance_groups') {
      instances = instancesList.filter(
        (item)=>
          item.instance_groups.includes(
            typeof selectInstanceMode.value === 'string'
              ? selectInstanceMode.value
              : selectInstanceMode.label)
      )
        .sort((a, b) => (a.instance_name.toLowerCase() > b.instance_name.toLowerCase() ? 1 : -1));
    }else {
      instances = instancesList
        .filter(
          (item) =>
            item[selectInstanceMode.key as keyof InstancePublicDataType]
              === selectInstanceMode.value,
        )
        .sort((a, b) => (a.instance_name.toLowerCase() > b.instance_name.toLowerCase() ? 1 : -1));
    }

    setTemporalInstancesList(instances);
  }, [selectInstanceMode]);

  const instanceButtons = selectInstanceButtons.map((button) => {
    return (
      <Button
        key={button.key}
        onClick={() =>
          onChangeSelectInstanceMode(button.key, button.selectInstanceMode, button.label)
        }
        size="medium"
        theme={
          selectInstanceMode.selectInstanceMode === button.selectInstanceMode ? 'fill' : 'outline'
        }
      >
        {button.label}
        {button.icon}
      </Button>
    );
  });

  const checkIfInstanceHasGroup = (key: string) => {
    if (!temporalInstanceData) {
      return '';
    }
    if (key === 'instance_groups' && temporalInstanceData.instance_groups.length === 0) {
      return '';
    }
    return temporalInstanceData.id;
  };

  return (
    <CustomModal
      handleClose={setShowModalInstance && setShowModalInstance}
      textCloseButton={selectToDefault ? 'Close ' : undefined}
      handleConfirm={confirmSelect}
      textSendButton={selectToDefault ? 'Mark as default ' : undefined}
      titleText={
        selectInstanceMode.selectInstanceMode === selectInstanceModeEnum.searchMode
          ? 'Search instance:'
          : 'Select instance:'
      }
      isAvailableConfirm={!temporalInstanceData}
      width={500}
    >
      {selectInstanceMode.selectInstanceMode === selectInstanceModeEnum.searchMode ? (
        <div className={cs.gapForm}>
          <Autocomplete
            id="grouped-instance"
            fullWidth
            options={instancesList.sort((a, b) => -b.server_name.localeCompare(a.server_name))}
            groupBy={(option) => option.server_name}
            getOptionLabel={(option) => option.instance_name}
            renderInput={(params) => (
              <TextField
                {...params}
                value={searchInputValue}
                fullWidth
                label="Enter instance name"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <React.Fragment>{params.InputProps.endAdornment}</React.Fragment>,
                }}
              />
            )}
            inputValue={searchInputValue}
            onInputChange={(event, newInputValue) => onChangeInputValue(newInputValue, event)}
            onChange={(event: React.ChangeEvent<{}>, newValue: InstancePublicDataType | null) => // eslint-disable-line
              onChangeHandlerInSearch(newValue)
            }
          />
          <div className={cs.selectInstanceLine}>Please select instance</div>
        </div>
      ) : (
        <div className={cs.gapForm}>
          <FormControl fullWidth>
            <InputLabel id="select_first_field">{selectInstanceMode.label}</InputLabel>
            <Select
              labelId="select_first_field"
              id="select_first_field"
              value={selectInstanceMode.value}
              label={selectInstanceMode.label}
              onChange={(e) => onChangeMenuFirstFieldItem(e)}
            >
              {menuFirstFieldItems(selectInstanceMode.key)}
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel id="select_instance">Instance</InputLabel>
            <Select
              disabled={selectInstanceMode.value === ''}
              labelId="select_instance"
              id="select_instance"
              value={checkIfInstanceHasGroup(selectInstanceMode.key)}
              label="Instance"
              onChange={(e) => onChangeMenuInstanceData(e)}
            >
              {menuInstancesItems}
            </Select>
          </FormControl>
        </div>
      )}

      <div className={s.modalButtonBlock}>{instanceButtons}</div>

      {selectToDefault && (
        <div className={s.modalButtonBlock}>
          <Button onClick={removeDefaultInstance} size="medium" theme="outline">
            Remove Default Instance
            <DeleteIcon className={cs.button__icon} />
          </Button>
        </div>
      )}
    </CustomModal>
  );
};

export default SelectInstance;
