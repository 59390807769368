import { InstanceExtendedPublicDataType, UniqueInstancePublicDataType } from '../types/appTypes';
import { sortKeyEnum } from '../types/tableTemplateTypes';
import { sortInfoType } from '../types/readersTypes';
import { utils_instance } from '../utils/Utils';

enum instancesListReducerTypes {
  setExtendedInfoList = 'instancesList/setExtendedInfoList',
  setSortExtendedInfoList = 'instancesList/setSortExtendedInfoList',
  setUniqueInfoList = 'instancesList/setUniqueInfoList',
  changeSelectedValues = 'instancesList/changeSelectedValues',
}

export const setExtendedInfoListAC = (instancesList: InstanceExtendedPublicDataType[]) => {
  return {
    type: instancesListReducerTypes.setExtendedInfoList,
    instancesList,
  } as const;
};

export const setSortExtendedInfoListAC = (keySort: sortKeyEnum) => {
  return {
    type: instancesListReducerTypes.setSortExtendedInfoList,
    keySort,
  } as const;
};

export const setUniqueInfoListAC = (instancesList: InstanceExtendedPublicDataType[]) => {
  return {
    type: instancesListReducerTypes.setUniqueInfoList,
    instancesList,
  } as const;
};

export const changeSelectedValuesAC = (
  multiSelectKey: string,
  multiSelectsValuesList: string[],
) => {
  return {
    type: instancesListReducerTypes.changeSelectedValues,
    multiSelectKey,
    multiSelectsValuesList,
  } as const;
};

export type setExtendedInfoListAT = ReturnType<typeof setExtendedInfoListAC>;
export type setSortExtendedInfoListAT = ReturnType<typeof setSortExtendedInfoListAC>;
export type setUniqueInfoListAT = ReturnType<typeof setUniqueInfoListAC>;
export type changeSelectedValuesAT = ReturnType<typeof changeSelectedValuesAC>;

export type actionType =
  | setExtendedInfoListAT
  | setSortExtendedInfoListAT
  | setUniqueInfoListAT
  | changeSelectedValuesAT;

type InitStateType = {
  instancesList: InstanceExtendedPublicDataType[];
  sortInfo: sortInfoType;
  uniqueList: UniqueInstancePublicDataType | null;
  filteredUniqueList: UniqueInstancePublicDataType | null;
};

export const initState: InitStateType = {
  instancesList: [],
  sortInfo: {
    keySort: sortKeyEnum.InstanceDetailName,
    directionSort: 1,
  },
  uniqueList: null,
  filteredUniqueList: null,
};

export const instancesListReducer = (state = initState, action: actionType): InitStateType => {
  switch (action.type) {
  case instancesListReducerTypes.setExtendedInfoList:
    const instancesList = utils_instance.sortInstancesDataList(
      state.sortInfo.keySort,
      state.sortInfo.directionSort,
      action.instancesList,
    );
    return { ...state, instancesList };
  case instancesListReducerTypes.setSortExtendedInfoList:
    const copyState = { ...state };

    copyState.sortInfo.directionSort =
        state.sortInfo.keySort === action.keySort ? state.sortInfo.directionSort * -1 : 1;

    copyState.sortInfo.keySort = action.keySort;

    const sortInstances = utils_instance.sortInstancesDataList(
      copyState.sortInfo.keySort,
      copyState.sortInfo.directionSort,
      copyState.instancesList,
    );

    copyState.instancesList = [...sortInstances];

    return copyState;
  case instancesListReducerTypes.setUniqueInfoList:
    const uniqueList = {
      region: Array.from(new Set(action.instancesList.map((obj) => obj.region))),
      server_name: Array.from(new Set(action.instancesList.map((obj) => obj.server_name))),
      city: Array.from(new Set(action.instancesList.map((obj) => obj.city))),
      country: Array.from(new Set(action.instancesList.map((obj) => obj.country))),
      territory: Array.from(new Set(action.instancesList.map((obj) => obj.territory))),
    };
    const filteredUniqueList = {
      region: [],
      server_name: [],
      city: [],
      country: [],
      territory: [],
    };

    return {
      ...state,
      uniqueList,
      filteredUniqueList: filteredUniqueList,
    };
  case instancesListReducerTypes.changeSelectedValues:
    if (!state.filteredUniqueList) {
      return state;
    }
    return {
      ...state,
      filteredUniqueList: {
        ...state.filteredUniqueList,
        [action.multiSelectKey]: action.multiSelectsValuesList,
      },
    };
  default: {
    return state;
  }
  }
};
