import React from 'react';
import s from './NotificationMessages.module.css';
import { useSelector } from 'react-redux';
import { AppRootStateType } from '../../../store/store';
import NotificationItem from './NotificationItem';
import { notificationType } from '../../../types/appTypes';

const NotificationMessages: React.FC = () => {
  const notificationsList = useSelector<AppRootStateType, notificationType[]>(
    (state) => state.app.notificationsList,
  );

  const notifications = notificationsList.map((item) => {
    return <NotificationItem key={item.created_at} item={item} />;
  });

  return <div className={s.notificationBody}>{notifications}</div>;
};

export default NotificationMessages;
