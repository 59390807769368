import { UserPublicDataType } from '../types/authTypes';

enum authReducerTypes {
  setToken = 'app/setToken',
  setUserInfo = 'app/setUserInfo',
}

export const setTokenAC = (token: string | null) => {
  return {
    type: authReducerTypes.setToken,
    token,
  } as const;
};

export const setUserInfoAC = (userInfo: UserPublicDataType) => {
  return {
    type: authReducerTypes.setUserInfo,
    userInfo,
  } as const;
};

type setTokenAT = ReturnType<typeof setTokenAC>;
type setUserInfoAT = ReturnType<typeof setUserInfoAC>;

export type actionType = setTokenAT | setUserInfoAT;

type InitStateType = {
  token: string | null;
  userInfo: UserPublicDataType | null;
  mainUserRole: number;
};

export const initState: InitStateType = {
  token: null,
  userInfo: null,
  mainUserRole: 0, //Block All
};

export const authReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case authReducerTypes.setToken: {
    const copyState = { ...state };

    copyState.token = action.token;

    return copyState;
  }

  case authReducerTypes.setUserInfo: {
    const copyState = { ...state };

    copyState.userInfo = action.userInfo;
    copyState.mainUserRole = action.userInfo.available_roles[0];

    return copyState;
  }

  default: {
    return state;
  }
  }
};
