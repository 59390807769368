import version from './version';

type configType = {
  auth0Domain: string;
  auth0Client: string;
  apiUrl: string;
  apiUrlWs: string;
  auth0Audience: string;
  version: string;
};

const config: configType = {
  auth0Domain:
      process.env.REACT_APP_AUTH0_DOMAIN ||
      'wework-corp-prod.wework-us.auth0.com',
  auth0Client:
      process.env.REACT_APP_AUTH0_CLIENT_ID ||
      'm0P2mWRGmiakQRLuHy7LoXowvWJWF9e3',
  apiUrl:
      process.env.REACT_APP_API_URL ||
      'https://bldg-tech-security-control-center-api-service-1.hydra.prod.wwrk.co',
  apiUrlWs:
      process.env.REACT_APP_WS_URL ||
      'wss://bldg-tech-security-control-center-api-service-1.hydra.prod.wwrk.co',
  auth0Audience:
      process.env.REACT_APP_AUTH0_AUDIENCE ||
      'wework',

  version: process.env.REACT_APP_VERSION || version,
};

export default config;
