import { HardwareNicknameDataType } from './readersTypes';

export type deviceAttributesType = {
  ip_address: string;
  mac_address: string;
  full_name: string;
  type: string;
  location?: string;
  status?: string;
  updated_at: Date;
  eduid?: string;
  last_checked: Date;
  enplug_api_id?: string;

  building_id?: number;
  subnet_id?: number;

  switch_name?: string;
  switch_description?: string;
  switch_ip?: string;
  switch_port?: number;

  floor?: string;
  vlan?: string;

  address?: string;
  building?: string;
  host?: string
  hostid?: number;
  hostname?: string;
  icmpping?: number;
  lat?: number;
  lon?: number;
  model?: string;
  region?: string;
  serial?: string;
  software?: string;
  territory?: string;
  vendor?: string;

  buildType?: string;
  customerId?: string;
  devType?: string;
  deviceName?: string;
  firmwarePackage?: string;
  hwVersion?: string;
  macAddr?: string;
  serialNumber?: string;
  swVersion?: string;
  variant?: string;
  variantId?: string;
};

export type DeviceDataType = {
  //type of device data
  id: number;
  type: string;
  isCheckedStatus: boolean;
  IsFavorite: boolean;
  attributes: deviceAttributesType;
  NicknameData: HardwareNicknameDataType;
};

export type DevicesDataListResponse = {
  //devices data list response
  devices: DeviceDataType[];
};

export enum deviceStatusNamesEnum {
  Online = 'Online',
  Offline = 'Offline',
  Unknown = 'Unknown',
}

export type deviceStatusDescriptionsType = {
  [key in deviceStatusNamesEnum]: string;
};
