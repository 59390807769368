import { Holidays } from '../types/holidaysTypes';

enum holidaysReducerTypes {
    setHolidaysData = 'holidays/setHolidaysDataList',
    updateHolidaysData = 'holidays/updateHolidaysDataList',
    removeHolidaysData = 'holidays/removeHolidaysData',
    addHolidaysData = 'holidays/addHolidaysData',
    setHolidaysGroupsData = 'holidays/setHolidaysGroupsData',
}

export const setHolidaysDataAC = (holidays: Holidays[]) => {
  return {
    type: holidaysReducerTypes.setHolidaysData,
    holidays,
  } as const;
};

export const addHolidaysDataAC = (holiday: Holidays) => {
  return {
    type: holidaysReducerTypes.addHolidaysData,
    holiday,
  } as const;
};

export const updateHolidaysDataAC = (holiday: Holidays) => {
  return {
    type: holidaysReducerTypes.updateHolidaysData,
    holiday,
  } as const;
};

export const removeHolidaysDataAC = (id: string) => {
  return {
    type: holidaysReducerTypes.removeHolidaysData,
    id,
  } as const;
};

export const setHolidaysGroupsDataAC = (holidaysGroups: string[]) => {
  return {
    type: holidaysReducerTypes.setHolidaysGroupsData,
    holidaysGroups,
  } as const;
};

export type setHolidaysDataAT = ReturnType<typeof setHolidaysDataAC>;
export type addHolidaysDataAT = ReturnType<typeof addHolidaysDataAC>;
export type removeHolidaysDataAT = ReturnType<typeof removeHolidaysDataAC>;
export type updateHolidaysDataAT = ReturnType<typeof updateHolidaysDataAC>;
export type setHolidaysGroupsDataAT = ReturnType<typeof setHolidaysGroupsDataAC>;

export type actionType =
    setHolidaysDataAT
    | addHolidaysDataAT
    | removeHolidaysDataAT
    | updateHolidaysDataAT
    | setHolidaysGroupsDataAT

type InitStateType = {
    holidays: Holidays[];
    holidaysGroups: string[];
};

export const initState: InitStateType = {
  holidays: [],
  holidaysGroups: [],
};

export const holidaysReducer = (
  state: InitStateType = initState,
  action: actionType,
): InitStateType => {
  switch (action.type) {
  case holidaysReducerTypes.setHolidaysData: {
    const copyState = { ...state };
    copyState.holidays = action.holidays;
    return copyState;
  }
  case holidaysReducerTypes.addHolidaysData: {
    const copyState = { ...state };
    copyState.holidays = [...copyState.holidays, action.holiday];
    return copyState;
  }
  case holidaysReducerTypes.updateHolidaysData: {
    const copyState = { ...state };
    copyState.holidays = [...copyState.holidays
      .filter(item => item.holiday_id !== action.holiday.holiday_id), action.holiday];
    return copyState;
  }
  case holidaysReducerTypes.removeHolidaysData: {
    const copyState = { ...state };
    copyState.holidays = [...copyState.holidays
      .filter(item => item.holiday_id !== action.id)];
    return copyState;
  }
  case holidaysReducerTypes.setHolidaysGroupsData: {
    const copyState = { ...state };
    copyState.holidaysGroups = action.holidaysGroups;
    return copyState;
  }
  default: {
    return state;
  }
  }
};
